import Vue from 'vue'
import Vuex from 'vuex'
import { router } from './routes.js'
import { BASE_URL } from './config.js'
import axios from 'axios'

const xhr = axios.create({ baseURL: `${BASE_URL}api/v1/` })

Vue.use(Vuex);

export const store = new Vuex.Store({
  state : {
    loop: true,
    shuffle: false,
    isLoading : false,
    isLoggedin :    false,
    isAdmin :   false,
    user :  null,
    playlists :  [],
    current_playing :   null,
    random_select: [],
    genres : [],
    base_url : `${ BASE_URL }`,
    axios_config : {},
    open_player: false,
    play_trigger_component: '',
    top_requests_list: [],
    likes: [],
    favorites: [],
    isSubscribed: false,
    perPage: 5,
    play_from_list: {
      list: '',
      fromList: false,
      selected: -1
    }
  },
  actions : {
    reAuth : (context) => {
      if (localStorage.getItem('soundlaunch.user') != null) {
        let user =  JSON.parse(localStorage.getItem('soundlaunch.user'));
        context.commit('saveUser', user);
        context.commit('loginUser');
        context.commit('setAdminRights', user.is_admin == "Y" ? true : false);
        let token = localStorage.getItem('soundlaunch.token');
        let config = {
          headers : {
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer ' + token
          }
        };
        context.commit('setAxiosConfig', config);
        axios.interceptors.request.use(
          (config) => {
            let token = localStorage.getItem('soundlaunch.token');
            if (token) {
              config.headers['Authorization'] = `Bearer ${ token }`;
              config.headers['Content-Type'] = `application/json`;
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      }
    },
    logout : (context) => {
      localStorage.removeItem("soundlaunch.token");
        localStorage.removeItem("soundlaunch.user");
        context.commit('logoutUser');
        context.commit('setAdminRights',false);
        context.commit('saveUser', null);
        router.push('/');
    },
    initUserLikes: async ({ commit, state }) => {
      if (state.user !== null) {
        await xhr.get(`liked/${state.user.id}`)
        .then(r => {
          commit('initUserLikes', r.data)
        })
        .catch(e => e)
      }
    },
    initUserFaves: async ({ commit, state }) => {
      if (state.user !== null) {
        await xhr.get(`favorite/${state.user.id}`)
        .then(r => {
          commit('initUserFaves', r.data.data)
        })
        .catch(e => e)
      }
    },
    postUserLike: async ({ dispatch, state }, songs) => {
      await xhr.post('liked', { user_id: state.user.id, songs })
      .then(() => {
        dispatch('initUserLikes')
      })
    },
    postUserFavorite: async ({ dispatch, state }, songs) => {
      await xhr.post('favorite', { user_id: state.user.id, songs })
      .then(() => {
        dispatch('initUserFaves')
      })
    },
    fetchUserPlaylists: async ({ commit, state }) => {
      if (state.user !== null) {
        await xhr.get(`playlists/${state.user.id}`)
          .then(r => {
            commit('fetchUserPlaylists', r.data.data)
          })
      }
    },
    fetchUserPurchaseState: async ({ commit, state }) => {
      if (state.user !== null) {
        await xhr.get(`purchases/${state.user.id}`)
        .then(r => commit('fetchUserPurchaseState', r.data.status))
      }
    },
    createPlaylist: async ({ state, dispatch }, { id, name }) => {
      await xhr.post(`playlists`, {
        name: name,
        user_id: state.user.id,
        songs: [id]
      })
      .then(() => { dispatch('fetchUserPlaylists') })
    },
    addMusicToPlaylist: async ({ dispatch }, { playlist_id, songs }) => {
      console.log(playlist_id, songs)
      await xhr.put(`playlists/${playlist_id}`, { songs: songs })
      .then((r) => { 
        console.log(r)
        dispatch('fetchUserPlaylists')
       })
    }
  },
  getters : {
    userLikes: state => {
      const ids = []
      if (state.likes === null || state.likes.length < 1) {
        return ids
      } else {
        state.likes.forEach(like => ids.push(like.id))
        return ids
  
      }
    },
    userFavorites: state => {
      const ids = []
      if (state.favorites === null || state.favorites.length < 1) {
        return ids
      } else {
        state.favorites.forEach(like => ids.push(like.id))
        return ids
  
      }
    },
    userValues: state => {
      return state.user;
    },
    axiosConfig: state => {
      return state.axios_config;
    },
    loginStatus: state => {
      return state.isLoggedin;
    },
    adminRights: state => {
      return state.isAdmin;
    },
    baseUrl: state => {
      return state.base_url;
    },
    genreList: state => {
      return state.genres;
    },
    currentPlaying : state => {
      return state.current_playing;
    },
    randomMusic : state => {
      return state.random_music;
    },
    openPlayer: state => {
      return state.open_player;
    },
    playTriggerComponent: state => {
      return state.play_trigger_component;
    },
    topRequestsList: state => {
      return state.top_requests_list;
    },
  },
  mutations : {
    toggleLoop: state => {
      state.loop = !state.loop
      if (state.loop == false) {
        state.shuffle = false
      }
    },
    toggleShuffle: state => {
      if (state.loop) {
        state.shuffle = !state.shuffle
      } else {
        state.shuffle = false
      }
    },
    loginUser: state => {
      state.isLoggedin = true;
    },
    logoutUser: state =>{
      state.isLoggedin = false;
      state.user = null;
    },
    setAdminRights: (state, is_admin) => {
      state.isAdmin = is_admin;
    },
    addToPlaylist: (state, music) => {
      state.playlist.push(music);
    },
    setPlaying: (state, music) => {
      state.current_playing = music;
    },
    saveUser: (state, user)=>{
      state.user = user;
    },
    setLoading: (state, isLoading) => {
      state.isLoading = true;
    },
    unsetLoading: (state, isLaoding) =>{
      state.isLoading = false
    },
    setGenres: (state, genres) =>{
      state.genres = genres;
    },
    setAxiosConfig: (state, config) => {
      state.axios_config = config;
    },
    setRandomMusic: (state, randomMusic) => {
      state.random_music = randomMusic;
    },
    setOpenPlayer: (state, openPlayer) => {
      state.open_player = openPlayer;
    },
    setPlayTriggerComponent: (state, playTriggerComponent) => {
      state.play_trigger_component = playTriggerComponent;
    },
    addToTopRequestList: (state, list) => {
      state.top_requests_list = state.top_requests_list.concat(list)
    },
    setTopRequestsList: (state, topRequestsList) => {
      if (state.top_requests_list.length > 0) {
        const local_index = state.top_requests_list.length - 1
        if (state.top_requests_list[local_index].music_id === topRequestsList[0].music_id) {
          state.top_requests_list.pop()
        }
      }
      state.top_requests_list = topRequestsList;
    },
    initUserLikes: (state, { songs }) => {
      songs.forEach(song => {
        if (song.img_file_ext) {
          song.album_art = `${song.cover_photo}.${song.img_file_ext}`
        } else {
          song.album_art = `${song.cover_photo}.jpg`
        }
      })
      state.likes = songs
    },
    initUserFaves: (state, fave) => {
      fave.forEach(song => {
        if (song.img_file_ext) {
          song.album_art = `${song.cover_photo}.${song.img_file_ext}`
        } else {
          song.album_art = `${song.cover_photo}.jpg`
        }
      })
      state.favorites = fave
    },
    fetchUserPlaylists: (state, playlists) => {
      state.playlists = playlists
    },
    fetchUserPurchaseState: (state, status) => {
      state.isSubscribed = status
    },
    PlayFromList: (state, { play, list, music }) => {
      if (play) {
        state.play_from_list.fromList = true
        state.play_from_list.list = list
        state.play_from_list.selected = music
      } else {
        state.play_from_list.fromList = false
        state.play_from_list.list = ''
        state.play_from_list.selected = -1
      }
    }
  }
});
