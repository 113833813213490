<template>
<!-- //@scroll.native="handleScroll" -->
  <div style="overflow: hidden;">
  	<div class="fixed-top">
	    <Header />
	    <FloatingPlayList
	    v-show="$store.state.isLoggedin"
	    :favoriteList="favorites"
	    :likes="likes"
	    @like="like"
	    @favorite="favorite"
	    @openPlaylistModal="openPlaylistModal"
	    ref="floatingPlaylist"
	    />
	</div>
	    <b-modal :active.sync="isCardModalActive" :can-cancel="false" :width="650" scroll="keep">
	      <ModalPlaylist :musicID="selectedMusicID" @close="isCardModalActive = false" />
	    </b-modal>
    <div style="margin-top: 115px">
	      <keep-alive>
	        <router-view
	        :favorites="favorites"
	        :likes="likes"
	        :PN="PN"
	        @like="like"
	        @favorite="favorite"
	        @openPlaylistModal="openPlaylistModal"
	        @initPlaylist="initPlaylist"
	        />
	      </keep-alive>
	</div>
	    <Footer />
	    <FloatingPlayer @playlistPN="PN = $event" />
	    <div v-if="scroll" class="floating-button" @click="goToTop" style="cursor: pointer">
	      <span><i class="fa fa-arrow-up"></i></span>
	    </div>
  </div>

</template>

<script>

import Header from "../components/Header";
import Footer from "../components/Footer";
import FloatingPlayer from '../components/FloatingPlayer.vue';
import FloatingPlayList from '../components/FloatingPlaylist.vue';
import ModalPlaylist from '../components/AddPlaylist-Modal.vue'

export default{
  components: {
    Header,
    FloatingPlayer,
    FloatingPlayList,
    ModalPlaylist,
    Footer
  },
  computed: {
    likes () {
      if (this.$store.state.isLoggedin) {
        return this.$store.getters.userLikes
      } else {
        return []
      }
    },
    favorites () {
      if (this.$store.state.isLoggedin) {
        return this.$store.getters.userFavorites
      } else {
        return []
      }
    }
  },
  data(){
    return {
      is_loading : false,
      scroll : false,
      isCardModalActive: false,
      selectedMusicID: -1,
      PN: {}
    }
  },
  methods : {
    initPlaylist () {
      this.$store.dispatch('initUserLikes')
        .then(() => {
          this.$refs.floatingPlaylist.updateLikes(this.$store.state.likes)
        })
      this.$store.dispatch('initUserFaves')
        .then(() => {
          this.$refs.floatingPlaylist.updateFavorites(this.$store.state.favorites)
        })
      this.$store.dispatch('fetchUserPlaylists')
      this.$store.dispatch('fetchUserPurchaseState')
      this.$refs.floatingPlaylist.initPlaylist()
    },
    requestTologin() {
      this.$buefy.toast.open({
        message: 'You need to log In!',
        type: 'is-danger'
      })
    },
    openPlaylistModal (id) {
      this.selectedMusicID = id
      this.isCardModalActive = true
    },
    async like (id) {
      let liked = []
      if (this.likes.find(f => f === id)) {
        liked = this.likes.filter(liked => liked !== id)
        this.$store.dispatch('postUserLike', liked)
          .then(() => {
            this.$store.dispatch('initUserLikes')
              .then(() => {
                this.$refs.floatingPlaylist.updateLikes(this.$store.state.likes)
              })
            this.$buefy.toast.open({
                message: 'Removed from Liked Songs',
                type: 'is-dark'
            })
          })
      } else {
        if (this.likes.length) {
          liked = this.likes
        }
        liked.push(id)
        this.$store.dispatch('postUserLike', liked)
          .then(() => {
            this.$store.dispatch('initUserLikes')
              .then(() => {
                this.$refs.floatingPlaylist.updateLikes(this.$store.state.likes)
              })
            this.$buefy.toast.open({
                message: 'Added to Liked Songs',
                type: 'is-dark'
            })
          })
      }
    },
    async favorite (id) {
      let fave = []
      if (this.favorites.find(f => f === id)) {
        fave = this.favorites.filter(favorite => favorite !== id)
        this.$store.dispatch('postUserFavorite', fave)
        .then(() => {
          this.$store.dispatch('initUserFaves')
            .then(() => {
              this.$refs.floatingPlaylist.updateFavorites(this.$store.state.favorites)
            })
          this.$buefy.toast.open({
            message: 'Removed from Favorite Songs',
            type: 'is-dark'
          })
        })
      } else {
        if (this.favorites.length) {
          fave = this.favorites
        }
        fave.push(id)
        this.$store.dispatch('postUserFavorite', fave)
        .then(() => {
          this.$store.dispatch('initUserFaves')
            .then(() => {
              this.$refs.floatingPlaylist.updateFavorites(this.$store.state.favorites)
            })
          this.$buefy.toast.open({
            message: 'Added to Favorite Songs',
            type: 'is-dark'
          })
        })
      }
    }
  },
  watch : {
    isLoading(newValue, oldValue){
      this.is_loading = newValue;
    }
  },
  created(){
    this.$store.dispatch('reAuth')
    this.$store.dispatch('initUserLikes')
      .then((r) => {
        this.$refs.floatingPlaylist.updateLikes(this.$store.state.likes)
      })
    this.$store.dispatch('initUserFaves')
      .then(() => {
        this.$refs.floatingPlaylist.updateFavorites(this.$store.state.favorites)
      })
    this.$store.dispatch('fetchUserPlaylists')
    this.$store.dispatch('fetchUserPurchaseState')

  },
  destroyed(){
    window.removeEventListener('scroll')
  }
}

</script>

<style>
    .floating-button{
        background: #1b1b1b;
        border-radius: 50%;
        padding: 15px 20px;
        color: #fff;
        position: fixed;
        bottom: 15px;
        right: 10px;
        z-index: 10;
    }
</style>


