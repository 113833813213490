<template>
    <nav class="navbar navbar-expand-lg nav-header">
        <router-link class="navbar-brand" id="navbarBrandMobile" :to="{ name: 'index' }">
            <img class="img-responsive" id="menuLogo" :src="`${base_url}images/sl-logo.png`" />
        </router-link>
        <!-- <a class="navbar-brand" id="navbarBrandMobile" href="javascript:void(0);">
                    <img class="img-responsive" id="menuLogo" :src="`${base_url}images/sl-logo.png`" />
      </a>-->
        <button ref="toggler" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-bars text-white"></span>
        </button>

        <div class="navbar-collapse justify-content-md-center collapse text-center" id="navbarHeader" style>
            <ul class="navbar-nav">
                <li class="nav-logo">
                    <router-link :to="{ name: 'index' }">
                        <img class="logo" :src="`${base_url}images/sl-logo.png`" />
                    </router-link>
                </li>
                <li class="home">
                    <router-link :to="{ name: 'index' }" class="nav-link">HOME</router-link>
                </li>
                <li class="Bboard">
                    <router-link :to="{ name: 'bulletin-board' }" class="nav-link">BULLETIN BOARD</router-link>
                </li>
                <li class="topRequest">
                    <router-link :to="{ name: 'top-requests' }" class="nav-link">TOP REQUESTS</router-link>
                </li>
                <li class="aboutUs">
                    <router-link :to="{ name: 'about-us' }" class="nav-link">ABOUT US</router-link>
                </li>
                <li :class="{'myAccount': isLoggedIn && !isAdmin}">
                    <router-link :to="{ name: 'my-account' }" v-if="isLoggedIn && !isAdmin" class="nav-link">MY ACCOUNT</router-link>
                </li>
                <li :class="{'signIn': !isLoggedIn}">
                    <router-link :to="{ name: 'sign-in' }" v-if="!isLoggedIn" class="nav-link">SIGN IN</router-link>
                </li>
                <li :class="{'dashboard': isLoggedIn && isAdmin}">
                    <router-link :to="{ name: 'dashboard' }" v-if="isLoggedIn && isAdmin" class="nav-link">DASHBOARD</router-link>
                </li>
                <li :class="{'signOut': isLoggedIn}">
                    <a class="nav-link" @click.prevent="logout" v-if="isLoggedIn">SIGN OUT</a>
                </li>
            </ul>

            <!-- <ul class="navbar-nav flex-row nav-logo" style="position: absolute; left: 45%; top: 10px">

                        <li><img class="logo" :src="`${base_url}/images/sl-logo.png`"></li>

        </ul>-->
        </div>
    </nav>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
        toggleHeader() {
            $(this.$refs.toggler).click();
        },
        handleScroll: function (event) {
            var scroll = $(window).scrollTop();

            if (window.matchMedia("(min-width: 769px)").matches) {
                if (scroll >= 100) {
                    $(".logo").addClass("shrink");
                    $(".nav-header").addClass("shrink");
                    // $('.nav-header').attr('style','padding-top : 8px !important');
                } else {
                    $(".logo").removeClass("shrink");
                    $(".nav-header").removeClass("shrink");
                    // $('.nav-header').attr('style','padding-top : 8px !important');
                }
            } else {
                $(".logo").addClass("shrink");
                $(".nav-header").addClass("shrink");
                // $('.nav-header').attr('style','padding-top : 8px !important');
            }
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "loginStatus",
            isAdmin: "adminRights",
            base_url: "baseUrl"
        })
    },
    mounted() {
        $(document).on("click", function () {
            $(".collapse").collapse("hide");
        });
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
};
</script>

<style scoped>
.logo {
    transition: all 0.5s;
    width: 110px;
    opacity: 1;
}

.navbar-expand-lg .navbar-nav .nav-link {
    transition: all 0.5s;
}

#navbarBrandMobile {
    width: 3em;
    margin: 0 auto 0 calc(100% - 50.2vw);
}

#navbarBrandMobile:hover {
    font-size: 18px !important;
}

.logo.shrink {
    transition: all 0.5s;
    width: 0px;
    opacity: 0;
}

.nav-header {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.19) !important;
    background-color: rgba(50, 50, 50, 0.5);
}

nav li,
a {
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
}

nav li,
a:hover {
    font-size: 19px;
    line-height: 1;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.nav-link {
    color: #fff !important;
}

@media only screen and (min-width: 992px) {
    .logo {
        transition: all 0.5s;
        width: 97px;
    }

    #navbarBrandMobile {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        margin: 32px 0px !important;
    }

    li.home {
        width: 90px;
    }

    li.Bboard {
        width: 190px;
    }

    li.topRequest {
        width: 160px;
    }

    li.aboutUs {
        width: 115px;
    }

    li.signIn {
        width: 90px;
    }

    li.signOut {
        width: 110px;
    }

    li.myAccount {
        width: 145px;
    }

    li.dashboard {
        width: 130.05px;
    }
}

@media only screen and (max-width: 991px) {
    #navbarBrandMobile {
        transform: translateX(-50%);
        left: 50%;
        position: relative;
        width: 3em;
        margin: 0;
    }
}

@media only screen and (min-width: 768px) {
    .nav-header {
        padding-top: 8px !important;
    }

    .navbar-nav {
        text-align: center !important;
    }

    .navbar-expand-lg.shrink .navbar-nav .nav-link {
        transition: all 0.5s;
        margin: 0px !important;
    }
}

@media only screen and (max-width: 320px) {
    /* #navbarBrandMobile {
        margin: 0 auto 0 calc(100% - 50.2vw);
    } */

    .nav-logo {
        display: none !important;
    }
}
</style>
