<template>

    <div class="mb-3">
        <h1>Music Management</h1>

        <div id="music-form" class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">

                        <div class="">
                            <img style="cursor: none !important" class="rounded image -file-preview" ref="image_file_preview" :src="`${base_url}cover_photos/default_cover.jpg`">
                            <!-- <button :class="[{ hidden: view || saving || !music_file }]" style="width: 100%" class=" btn btn-success" @click="openUpload">Click to upload image</button> -->
                            <button style="width: 100%" class=" btn btn-success" @click="openUpload">Click to upload image</button>
                            <div class="form-group" style="display: none;">
                                <label for="file">File</label>
                                <input type="file" ref="image_file" accept="image/*" @change="previewImageFile" class="form-control-file border">
                            </div>
                        </div>

                        <audio ref="audio" controls>
                            <source src="" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>

                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <form name="music_form" enctype="multipart/form-data" @submit.prevent>
                            <div class="form-group" :class="[{ 'hidden': view || edit },'']">
                                <label for="file">Music File (.mp3 only)</label>
                                <input :disabled="view || edit" accept=".mp3,audio/*" type="file" ref="music_file" @change="previewMusicFile" class="form-control-file border">
                                <p class="text-danger" v-if="errors.music_file">{{ errors.music_file[0] }}</p>
                            </div>


                            <div class="form-group">
                                <label for="title">Title</label>
                                <input :disabled="view" type="text" v-model="title" id="title" name="title" class="form-control">
                                <p class="text-danger" v-if="errors.title">{{ errors.title[0] }}</p>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label for="genre">Genre</label>
                                        <!-- <select :disabled="view" v-model="genre" class="form-control">
                                            <option>Select Genre</option>
                                            <option v-for="genre in genres" :value="genre.id">
                                                {{ genre.description }}
                                            </option>
                                        </select> -->
                                        <multiselect
                                            v-model="add_selected_genres"
                                            :disabled="view"
                                            tag-placeholder="Add this as new genre"
                                            placeholder="Search or add a genre"
                                            label="description"
                                            track-by="id"
                                            :options="genre_options"
                                            :multiple="true"
                                            :taggable="true"
                                            @tag="addGenre">

                                        </multiselect>
                                        <p class="text-danger" v-if="errors.genre">{{ errors.genre[0] }}</p>
                                    </div>
                                    <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <button :disabled="view || saving" style="margin-top: 50%" class="btn btn-primary" @click="addGenre"><i class="fa fa-plus"></i></button>
                                    </div> -->
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="artist">Artist</label>
                                <input :disabled="view" type="text" v-model="artist" class="form-control">
                                <p class="text-danger" v-if="errors.artist">{{ errors.artist[0] }}</p>
                            </div>
                            <div class="form-group">
                                <label for="comment">Comment</label>
                                <textarea :disabled="view" v-model="comment" rows="5" class="form-control"></textarea>
                            </div>

                            <div class="pull-right">
                                <button ref="cancel_save_music" @click="resetForm" class="btn btn-primary">Cancel</button>
                                <button v-if="!view" type="submit" ref="save_music" @click="saveMusic" class="btn btn-success">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                        <ul class="pagination">
                            <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
                                <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.prev_page)">Previous</a>
                            </li>
                            <li class="page-item disabled">
                                <a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a>
                            </li>
                            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
                                <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.next_page)">Next</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                        <div class="search-form form-group">
                            <span class="fa fa-search"></span>
                            <input type="text" v-model="search_val" @keyup="searchMusic" placeholder="Search" class="form-control">

                        </div>
                    </div>
                </div>
                <div v-if="musics.length!=0">
                    <div v-for="music in musics" v-bind:key="music.id" class="card card-body mt-2">
                        <div class="btn-group dropleft">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="fa fa-ellipsis-v"></span>
                            </button>
                            <div class="dropdown-menu">
                            <a @click="updateMusic(music)" href="javascript:;" class="dropdown-item update">Update</a>
                            <a @click="deleteMusic(music.id)" href="javascript:;" class="dropdown-item">Delete</a>
                            <a @click="viewMusic(music)" href="javascript:;" class="dropdown-item">Play</a>
                            <a @click="updateStatus(music)" href="javascript:;" class="dropdown-item">{{ music.status=="A" ? "Disable" : "Enable" }}</a>
                            <!-- <a @click="updateIsFeatured(music)" href="javascript:;" class="dropdown-item">{{ music.is_featured=="Y" ? "Set featured" : "Unset featured" }}</a> -->
                            </div>
                        </div>
                        <div class="">
                            <div class="music-photo">
                                <img style="cursor: none !important" class="rounded" :src="`${ base_url }cover_photos/${ music.album_art }`">
                            </div>
                            <div class="music-description">
                                <span class="music-title">{{ music.title }} </span><span v-if="music.status=='I'" class="badge badge-secondary">For confirmation</span>
                                <span class="music-artist">{{ music.artist }}</span>
                                <span class="music-uploader" style="margin-bottom: 0">Uploaded by : {{ music.uploaded_by.first_name+' '+music.uploaded_by.last_name }}</span>
                                <span class="music-uploader" style="margin-bottom: 0">{{ music.uploaded_by.email }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center mt-3">
                    <h4 class="text-muted"><i class="fa fa-frown-o"></i> No record(s) found.</h4>
                </div>
            </div>
        </div>

        <div class="modal " ref="updateModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" @click="closeUpdateModal" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">

                                <div class="">
                                    <img class="rounded image -file-preview" ref="up_image_file_preview" :src="`${base_url}cover_photos/default_cover.jpg`">
                                    <button style="width: 100%" class=" btn btn-success" @click="openUpload">Click to upload image</button>
                                    <div class="form-group" style="display: none;">
                                        <label for="file">File</label>
                                        <input type="file" accept="image/*" ref="up_image_file" @change="previewImageFile" name="up_image_file" class="form-control-file border">
                                    </div>
                                </div>

                                <audio ref="up_audio" controls>
                                    <source src="" type="audio/mpeg">
                                    Your browser does not support the audio element.
                                </audio>

                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <form name="music_form" enctype="multipart/form-data" @submit.prevent>
                                    <div class="form-group" :class="{ 'hidden': view || !edit }">
                                        <label for="file">File</label>
                                        <input type="file" accept=".mp3,audio/*" ref="up_image_file" @change="previewMusicFile" name="up_music_file" class="form-control-file border">
                                    </div>

                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input type="text" v-model="up_title" name="up_title" class="form-control">
                                        <p class="text-danger" v-if="update_errors.title">{{ update_errors.title[0] }}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="genre">Genre</label>
                                        <multiselect
                                            v-model="up_selected_genres"

                                            tag-placeholder="Add this as new genre"
                                            placeholder="Search or add a genre"
                                            label="description"
                                            track-by="id"
                                            :options="genre_options"
                                            :multiple="true"
                                            :taggable="true"
                                            @tag="addGenre">

                                        </multiselect>

                                        <!-- <select v-model="up_genre" class="form-control">
                                            <option>Select Genre</option>
                                            <option v-for="genre in genres" :value="genre.id">
                                                {{ genre.description }}
                                            </option>
                                        </select> -->
                                        <p class="text-danger" v-if="update_errors.genre">{{ update_errors.genre[0] }}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="artist">Artist</label>
                                        <input type="text" v-model="up_artist" name="artist" class="form-control">
                                        <p class="text-danger" v-if="update_errors.artist">{{ update_errors.artist[0] }}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="comment">Comment</label>
                                        <textarea v-model="up_comment" name="up_comment" rows="5" class="form-control"></textarea>
                                    </div>

                                    <div class="pull-right">
                                        <button ref="cancel_update_music" @click="closeUpdateModal" class="btn btn-primary">Clear</button>
                                        <button  type="submit" ref="update_music" @click="saveMusic" class="btn btn-success">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>


                </div>
            </div>
        </div>

        <div class="modal " ref="genreModal">
            <div class="modal-dialog ">
                <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" @click="closeGenreModal" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="card-body">
                        <form name="music_form" enctype="multipart/form-data" @submit.prevent>

                            <div class="form-group">
                                <label for="new_genre">Genre</label>
                                <input type="text" v-model="new_genre" name="new_genre" id="new_genre" class="form-control">
                                <p class="text-danger" v-if="errors.description">{{ errors.description[0] }}</p>
                            </div>

                            <div class="pull-right">
                                <button ref="cancel_save_genre" @click=" new_genre='' " class="btn btn-primary">Clear</button>
                                <button  type="submit" ref="save_genre" @click="saveGenre" class="btn btn-success">Save</button>
                            </div>
                        </form>
                    </div>

                </div>


                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect'

export default {

    data() {
        return {
            title: "",
            comment: "",
            artist: "",
            genre: [],
            musics: [],
            music_id : "",
            image_file: null,
            music_file: null,
            cover_photo: "",
            view: false,
            edit: false,
            saving: false,
            edit_music: null,
            pagination: {},

            // Update variables
            search_val: "",
            up_title: "",
            up_comment: "",
            up_artist: "",
            up_genre: "",
            up_image_file: "",
            up_music_file: "",
            up_cover_photo: "",

            new_genre: "",
            errors: [],
            update_errors: [],

            genre_options: [],
            add_selected_genres: [],
            up_selected_genres: []
        }
    },
    methods: {
        fetchMusic(page_url = "") {
            let vm = this;
            let url = this.base_url+"api/v1/musics";

            if (page_url !=="") {
               url = page_url;
            }

            if (this.search_val !== "") {
                if (page_url !== "") {
                    url += "&search="+this.search_val;
                } else {
                    url += "?search="+this.search_val;
                }
            }

            axios.get(url, this.axios_config)
                .then(response => {
                    this.musics = response.data.data;
                    this.makePagination(response.data.meta, response.data.links);
                })
                .catch(error=>console.log(error));
        },
        fetchGenres() {
            axios.get(this.base_url + 'api/v1/genre')
                .then(response => {
                    this.$store.commit('setGenres', response.data.data);
                    this.genre_options = [];
                    for (let i = 0; i < this.genres.length; i++) {

                        let x = this.genres[i];

                        this.genre_options.push({
                            "id" : x.id,
                            "description" : x.description
                        })
                    }
                })
                .catch(error => console.log(error));
        },
        searchMusic() {
            setTimeout(function() {
                this.fetchMusic();
            }.bind(this), 2000);
        },
        makePagination(meta, links) {
            let pagination = {
                current_page    :   meta.current_page,
                last_page       :   meta.last_page,
                next_page       :   links.next,
                prev_page       :   links.prev,
            }

            this.pagination = pagination;
        },
        addGenre(newGenre) {
            // $(this.$refs.genreModal).modal('show');
            // $(this.$refs.genreModal).find('#new_genre').focus();

            let g = {
                "description" : newGenre
            }

            axios.post(this.base_url+'api/v1/genre', g, this.axios_config)
                .then(response => {
                    this.$swal({
                        // position: 'top-end',
                        type: 'success',
                        title: "Genre added.",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(result => {
                        this.$store.commit('setGenres', response.data.data);
                        this.fetchGenres();
                        // $(this.$refs.save_genre).removeAttr('disabled');
                        // $(this.$refs.cancel_save_genre).removeAttr('disabled');
                        // $(this.$refs.save_genre).text('Save');
                        //this.genre = this.genres.filter( genre => genre.description == this.new_genre)[0].id;
                        //this.closeGenreModal();
                    });
                })
                .catch(error=>{
                    if (error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                    // $(this.$refs.save_genre).removeAttr('disabled');
                    // $(this.$refs.cancel_save_genre).removeAttr('disabled');
                    // $(this.$refs.save_genre).text('Save');
                    console.log(error);
                });
        },
        closeGenreModal() {
            this.new_genre = "";
            this.errors = [];
            $(this.$refs.genreModal).modal('hide');
        },
        saveGenre() {
            let g = {
                "description": this.new_genre
            }

            $(this.$refs.save_genre).attr('disabled', 'disabled');
            $(this.$refs.cancel_save_genre).attr('disabled','disabled');
            $(this.$refs.save_genre).text('Saving...');

            axios.post(this.base_url + 'api/v1/genre', g, this.axios_config)
                 .then(response=>{
                    this.$swal({
                        // position: 'top-end',
                        type: 'success',
                        title: "Genre added.",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(result=>{
                        this.$store.commit('setGenres', response.data.data);
                        $(this.$refs.save_genre).removeAttr('disabled');
                        $(this.$refs.cancel_save_genre).removeAttr('disabled');
                        $(this.$refs.save_genre).text('Save');
                        this.genre = this.genres.filter( genre => genre.description == this.new_genre)[0].id;
                        this.closeGenreModal();
                    });
                 })
                 .catch(error=>{
                    if (error.response.data) {
                        this.errors = error.response.data.errors;
                    }

                    $(this.$refs.save_genre).removeAttr('disabled');
                    $(this.$refs.cancel_save_genre).removeAttr('disabled');
                    $(this.$refs.save_genre).text('Save');
                    console.log(error);
                 });
        },
        saveMusic() {
            let formData = new FormData();

            if (this.image_file) {
                formData.append('image_file', this.image_file);
            }

            formData.append('artist', this.artist);
            formData.append('title', this.title);
            formData.append('comment', this.comment);
            formData.append('user', this.user.id);
            formData.append('status', 'A');

            if (this.edit) {
                formData =  new FormData();

                if (this.up_music_file) {
                    formData.append('music_file',this.up_music_file);
                }

                if (this.up_image_file) {
                    formData.append('image_file',this.up_image_file);
                }

                this.up_genre = [];
                for (let i = 0; i < this.up_selected_genres.length; i++) {
                    this.up_genre.push(
                        this.up_selected_genres[i].id
                    );
                }

                formData.append('artist', this.up_artist);
                formData.append('title', this.up_title);
                formData.append('comment', this.up_comment);
                formData.append('genre', this.up_genre);
                formData.append('status', this.edit_music.status);
                formData.append('_method','put');

                $(this.$refs.update_music).attr('disabled', 'disabled');
                $(this.$refs.cancel_update_music).attr('disabled', 'disabled');
                $(this.$refs.update_music).text('Updating...');
                this.saving = true;

                axios.post(this.base_url + 'api/v1/music/' + this.edit_music.id, formData, {"headers": this.axios_config.headers})
                    .then(response=>{
                        this.$swal({
                            // position: 'top-end',
                            type: 'success',
                            title: "Music updated.",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(result=>{
                            this.closeUpdateModal();
                            this.fetchMusic();
                            $(this.$refs.update_music).removeAttr('disabled');
                            $(this.$refs.cancel_update_music).removeAttr('disabled');
                            $(this.$refs.update_music).text('Update');
                            this.saving = false;
                        });
                    })
                    .catch(error => {
                        if(error.response.data){
                            this.update_errors = error.response.data.errors;
                        }

                        console.log(error);
                        $(this.$refs.update_music).removeAttr('disabled');
                        $(this.$refs.cancel_update_music).removeAttr('disabled');
                        $(this.$refs.update_music).text('Update');
                        this.saving = false;

                    });
            } else {
                formData.append('music_file', this.music_file);
                this.genre = [];

                for (let i = 0; i < this.add_selected_genres.length; i++){
                    this.genre.push(
                        this.add_selected_genres[i].id
                    );
                }

                formData.append('genre', this.genre );
                $(this.$refs.save_music).attr('disabled','disabled');
                $(this.$refs.cancel_save_music).attr('disabled','disabled');
                $(this.$refs.save_music).text('Saving...');
                this.saving = true;

                axios.post(this.base_url + 'api/v1/music', formData, this.axios_config)
                    .then(response=>{
                        this.$swal({
                            // position: 'top-end',
                            type: 'success',
                            title: "Music saved.",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(result=>{
                            this.resetForm();
                            this.fetchMusic();
                            $(this.$refs.save_music).removeAttr('disabled');
                            $(this.$refs.cancel_save_music).removeAttr('disabled');
                            $(this.$refs.save_music).text('Save');
                            this.saving = false;
                        });
                    })
                    .catch(error => {
                        if (error.response.data) {
                            if (typeof error.response.data.errors == 'undefined') {
                                this.$swal({
                                    type: 'error',
                                    title: "Error!",
                                    text: "Server Error while saving music to database.",
                                    showConfirmButton: false,
                                    timer: 1500
                                })

                                console.log(error.response.data.message)
                            } else {
                                this.errors = error.response.data.errors;
                            }
                        }

                        $(this.$refs.save_music).removeAttr('disabled');
                        $(this.$refs.cancel_save_music).removeAttr('disabled');
                        $(this.$refs.save_music).text('Save');
                        this.saving = false;
                    });
            }
        },
        updateMusic(music) {
            this.$refs.up_audio.src = this.base_url+"music/"+music.audio;
            this.$refs.up_audio.load();
            this.$refs.up_audio.play();
            this.$refs.up_image_file_preview.src = this.base_url+"cover_photos/"+music.album_art;
            this.up_title = music.title;
            this.up_artist = music.artist;
            this.up_comment = music.comment;
            this.up_selected_genres = [];
            for (let i = 0; i < music.genres.length; i++) {
                this.up_selected_genres.push({
                    'id': music.genres[i].id,
                    'description': music.genres[i].description
                });
            }

            this.edit = true;
            this.view = false;
            this.edit_music = music;

            $(this.$refs.updateModal).modal('show');
        },
        closeUpdateModal() {
            this.resetForm();

            $(this.$refs.updateModal).modal('hide');
        },
        updateStatus(music) {
            let formData =  new FormData();
            formData.append("id", music.id);
            formData.append("status", music.status === "A" ? "I" : "A");
            formData.append("_method","put");

            axios.post(this.base_url+'api/v1/update-status/music/' + music.id, formData, this.axios_config)
                 .then(response => {
                    this.$swal({
                        // position: 'top-end',
                        type: 'success',
                        title: "Music updated.",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(result => {
                        this.fetchMusic();
                    });
                 })
                 .catch(error=>console.log(error));
        },
        updateIsFeatured(music) {
            let formData =  new FormData();
            formData.append("id", music.id);
            formData.append("is_featured", music.is_featured === "Y" ? "N" : "Y");
            formData.append("_method","put");

            axios.post(this.base_url+'api/v1/update-is-featured/music/'+music.id, formData, this.axios_config)
                 .then(response => {
                    this.$swal({
                        // position: 'top-end',
                        type: 'success',
                        title: "Music updated.",
                        showConfirmButton: false,
                        timer: 1500
                    }).then(result => {
                        this.fetchMusic();
                    });
                 })
                 .catch(error => console.log(error));
        },
        deleteMusic(id) {
            this.$swal({
                title: 'Are you sure you want to delete this music?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(result => {
                if (result.value) {
                    axios.post(`${ this.base_url }api/v1/music/${id}`, { _method : "delete"}, this.axios_config)
                        .then(response => {
                            this.$swal({
                                // position: 'top-end',
                                type: 'success',
                                title: 'Music deleted',
                                showConfirmButton: false,
                                timer: 1500
                            }).then(result=>{
                                this.fetchMusic();
                                this.resetForm();
                            });
                        })
                        .catch(error=>console.log(error));
                }
            })

        },
        viewMusic(music) {
            this.$refs.audio.src = this.base_url+"music/"+music.audio;
            this.$refs.audio.load();
            this.$refs.audio.play();
            this.$refs.image_file_preview.src = this.base_url+"cover_photos/"+music.album_art;
            this.title = music.title;
            this.artist = music.artist;
            this.comment = music.comment;
            this.add_selected_genres = [];

            for (let i = 0; i < music.genres.length; i++) {
                this.add_selected_genres.push({
                    'id': music.genres[i].id,
                    'description': music.genres[i].description
                });
            }

            this.view = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        previewMusicFile(event) {
            if (this.edit) {
                this.up_music_file = event.target.files[0];
                this.$refs.up_audio.src = URL.createObjectURL(this.up_music_file);
                this.$refs.up_audio.load();
                this.$refs.up_audio.play();
                let filename = this.up_music_file.name;
                this.title = filename.split('.')[0];
            } else {
                this.music_file = event.target.files[0];
                this.$refs.audio.src = URL.createObjectURL(this.music_file);
                this.$refs.audio.load();
                this.$refs.audio.play();
                let filename = this.music_file.name;
                this.title = filename.split('.')[0];
            }
        },
        previewImageFile(event) {
            if (this.edit) {
                this.up_image_file = event.target.files[0];
                this.$refs.up_image_file_preview.src = URL.createObjectURL(this.up_image_file);
            } else {
                this.image_file = event.target.files[0];
                this.$refs.image_file_preview.src = URL.createObjectURL(this.image_file);
            }
        },
        openUpload() {
            this.$refs.image_file.click();
        },
        resetForm() {
            this.$refs.music_file.type = 'text';
            this.$refs.music_file.type = 'file';

            this.$refs.image_file.type = 'text';
            this.$refs.image_file.type = 'file';
            this.$refs.image_file_preview.src = this.base_url+"cover_photos/default_cover.jpg";
            this.$refs.audio.src = "";
            this.$refs.audio.load();

            this.up_music_file = null;
            this.up_artist = "";
            this.up_title = "";
            this.up_comment = "";
            this.up_genre = "";
            this.add_selected_genres = [];
            this.up_selected_genres = [];

            this.$refs.up_image_file.type = 'text';
            this.$refs.up_image_file.type = 'file';
            this.$refs.up_image_file_preview.src = this.base_url+"cover_photos/default_cover.jpg";
            this.$refs.up_audio.src = "";
            this.$refs.up_audio.load();

            this.music_file = null;
            this.artist = "";
            this.title = "";
            this.comment = "";
            this.genre = "";

            this.view = false;
            this.edit = false;
            this.edit_music = null;
            this.errors = [];
            this.update_errors = [];
        },
        redirect() {
            if (this.user.is_admin != "Y") {
                this.$router.push('/');
            }
        }
    },
    created() {
        this.fetchMusic();
        this.fetchGenres();
        this.redirect();
    },
    computed: {
        ...mapGetters({
            base_url: 'baseUrl',
            user: 'userValues',
            axios_config: 'axiosConfig',
            genres: 'genreList'
        })
    },
    components: {
        Multiselect
    }
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.disabled-music{
    background: #bdbdbd;
}

img{
    width: 100%;
    height: auto;
}

audio{
    width: 100%;
}

#upload_image{
    width: 100%;
}

.album-art{
    height: 100px;
    width: 100px;
}

.music-description{
    position: absolute;
    top: 15px;
    left: 130px;
}

.music-photo{
    display: table-cell;
    vertical-align: top;
}

.music-description{
    display: table-cell;
    vertical-align: top;
}

.hidden{
    display: none !important;
}

.music-title{
    font-weight: 500;
}

.music-artist{
    display: block;
}

.music-uploader{
    display: block;
}

</style>
