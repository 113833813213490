
import Router from 'vue-router';

export const router = new Router({

    mode : "history",
    routes : [
        {
            path    :   "/",
            name    :   "index",
            component   :   require("./client/Home.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/home",
            name    :   "home",
            redirect    :   "/",
            meta    :   { guest: true }
        },
        {
            path    :   "/my-account",
            name    :   "my-account",
            component   :   require("./client/MyAccount.vue").default,
            meta    :   { auth: true }
        },
        {
            path    :   "/bulletin-board",
            name    :   "bulletin-board",
            component   :   require("./client/BulletinBoard.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/top-requests",
            name    :   "top-requests",
            component   :   require("./client/TopRequests.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/about-us",
            name    :   "about-us",
            component   :   require("./client/AboutUs.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/privacy-policy",
            name    :   "privacy-policy",
            meta    :   { guest: true },
            component   :   require("./client/PrivacyPolicy.vue").default
        },
        {
            path    :   "/sign-in",
            name    :   "sign-in",
            component   :   require("./client/Login.vue").default
        },
        {
            path    :   "/floating-playlist",
            name    :   "floating-playlist",
            component   :   require("./components/FloatingPlaylist.vue").default
        },
        {
            path    :   "/register",
            name    :   "register",
            component   :   require("./client/Register.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/forgot-password",
            name    :   "forgot-password",
            component   :   require("./client/ForgotPassword.vue").default,
            meta    :   { guest: true }
        },
        {
            path    :   "/admin/dashboard",
            name    :   "dashboard",
            meta    :   { layout: "sidebar" , auth: true },
            component   :   require("./admin/Dashboard.vue").default,

        },
        {
            path    :   "/admin/ads",
            name    :   "ads",
            meta    :   { layout: "sidebar", auth: true  },
            component   :   require("./admin/Ads.vue").default
        },
        {
            path    :   "/admin/music",
            name    :   "music",
            meta    :   { layout: "sidebar", auth: true  },
            component   :   require("./admin/Music.vue").default
        },
        {
            path    :   "/admin/users",
            name    :   "users",
            meta    :   { layout: "sidebar" , auth: true  },
            component   :   require("./admin/Users.vue").default
        },
        // {
        //     path    :   "*",
        //     redirect    :   "/home"
        // }
        {
            path    :   "*",
            name    :   "404",
            meta    :   { guest: true },
            component   :   require("./components/404.vue").default
        }
    ]

});

router.beforeEach((to, from, next) => {

    if(to.matched.some(record => record.meta.guest)) {
        next()
    }else if( to.matched.some(record => record.meta.auth )) {
        if( localStorage.getItem('soundlaunch.user') == null ) {
            next( { name : "sign-in" })
        }else{
            next()
        }
    }else{
        next()
    }

})
