<template>
  <div class="floating-player" :class="[{ 'open' : player_open },'']" @mouseover="openPlayer" @mouseleave="closePlayer">
    <div class="ready-player-1 audio-player" :class="[{ 'open' : player_open },'']">
      <div class="player-controls">
        <span class="controls control-play" @click="toggleMusic">
          <i :class="[playing ? 'fa fa-pause' : 'fa fa-play' ]"></i>
        </span>
        <span @click="getRandom('prev')" class="controls control-backward" :class="[{'hidden' : !player_open},'']">
          <i class="fa fa-backward"></i>
        </span>
        <span @click="getRandom('next')" class="controls control-forward" :class="[{'hidden' : !player_open},'']">
          <i class="fa fa-forward"></i>
        </span>
        <span class="controls control-volume" :class="[{'hidden' : !player_open},'']">
          <i class="fa fa-volume-down"></i>
          <span class="volume" @click="setVolume"></span>
          <span class="volume-seeked" :style="{ height: volume_height + '%' }"></span>
          <span class="volume-cursor" ref="volume_cursor"></span>
        </span>
        <span class="playing-cover" :class="[{'hidden' : !player_open},'']">
          <img v-if="now_playing" :src="`${base_url}cover_photos/${now_playing.album_art }`">
        </span>
        <div class="track-control" :class="[{'hidden' : !player_open},'']" @click="setCurrentTime">
          <span class="track"></span>
          <span class="track-cursor" ref="track_cursor" :style="{ left: progress_width + '%' }"></span>
          <span class="track-seeked" :style="{ width: progress_width + '%' }"></span>
          <span v-if="now_playing" class="playing-title">{{ now_playing.title || `&nbsp;` }}</span>
          <span v-if="now_playing" class="playing-artist">{{ now_playing.artist || `&nbsp;` }}</span>
        </div>
        <div class="track-time" :class="[{'hidden' : !player_open},'']">
          <span v-if="now_playing" class="playing-duration">{{ current_time }}</span>
          <span v-if="now_playing" class="playing-time">{{ total_time }}</span>
        </div>
      </div>
      <div class="hidden">
        <audio class="hidden" id="audioPlayer" ref="audio" autoplay="true" allow="autoplay" preload="auto" controls @loadedmetadata="audioLoadedMetaData" @seeking="audioSeeking" @seeked="audioSeeked" @timeupdate="audioUpdateProgress" @onplay="audioPlayed" @paused="audioPaused" @onended="audioEnded" v-on:canplay="audioCanPlay">
          <source ref="audio_player" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      listSource: [],
      playing: false,
      paused: false,
      stopped: false,
      loading: false,
      now_playing: null,
      player_open: this.$store.state.open_player,
      total_time: "0:00",
      current_time: "0:00",
      currentTime: 0,
      progress_width: 0,
      volume_height: 50,
      base_url: "",
      listNav: {
        prev: {},
        next: {}
      }
    }
  },
  computed: {
    ...mapState([
      'current_playing',
      'random_music',
      'open_player',
      'top_requests_list',
      'shuffle',
      'loop'
    ]),
    ...mapGetters({
      top_requests: 'topRequestsList'
    })
  },
  methods: {
    playCurrent(musicObj) {
      if (this.$store.state.play_from_list.fromList) {
        this.setPlayerList(musicObj.id)
      }
      this.$refs.audio_player.src = this.base_url + 'music/' + musicObj.audio;
      this.$refs.audio.src = this.base_url + 'music/' + musicObj.audio;
      this.$refs.audio.volume = .5;
      var promise = this.audioPlayed();
      if (promise !== undefined) {
        promise.then(_ => {
          if (this.$router.currentRoute.name != 'top-requests') {
            this.openPlayer();
          }

          setTimeout(function () {
            this.player_open = false;
          }.bind(this), 3000);

            this.playing = true;
        }).catch(error => {
            console.log('Autoplay was prevented.', error.message);
            this.audioPaused();
        });
      } else {
        this.player_open = true;
        setTimeout(function () {
          this.player_open = false;
        }.bind(this), 3000);
        this.playing = true;
      }
    },
    toggleMusic() {
      this.player_open = true;
      setTimeout(function () {
        this.player_open = false;
      }.bind(this), 3000);
      if (this.now_playing) {
        if (this.playing == true) {
          this.$refs.audio.pause();
          this.audioPaused();
        } else {
          this.$refs.audio.play();

          var promise = document.querySelector('audio').play();

          if (promise !== undefined) {
            promise.then(_ => {
              this.audioPlayed();
            }).catch(error => {
              console.log('Autoplay was prevented.', error.message);
              this.audioPaused();
            });
          } else {
            this.audioPlayed();
          }
        }
      }
    },
    togglePlayer() {
      this.player_open = !this.player_open;
      this.$store.commit("setOpenPlayer", !this.player_open);
    },
    openPlayer() {
      const disablePlayerOpenComponents = [
        "transition-group",
        "bulletin-board",
        "footer"
      ];
      if (disablePlayerOpenComponents.includes(this.$store.state.play_trigger_component)) {
        this.closePlayer();
      } else {
        this.player_open = true;
        this.$store.commit("setOpenPlayer", true);
      }
      this.$store.state.play_trigger_component = null;
    },
    closePlayer() {
      setTimeout(function () {
        this.player_open = false;
        this.$store.commit("setOpenPlayer", false);
      }.bind(this), 2000);
    },
    setVolume(event) {
      const volumePart = event.offsetY / event.target.offsetHeight;
      this.volume_height = 100 - (volumePart * 100);
      this.$refs.audio.volume = (100 - (volumePart * 100)) / 100;
    },
    audioLoadedMetaData() {
      this.loading = true;
      this.total_time = this.formatTime(this.$refs.audio.duration);
      this.current_time = "0:00";
      this.progress_width = 0;
    },
    audioPlayed() {
      this.loading = false;
      this.playing = true;
      return this.$refs.audio.play();
    },
    audioPaused() {
      this.loading = false;
      this.playing = false;
      this.paused = true;
      return this.$refs.audio.pause();
    },
    audioEnded() {
      this.current_time = "0:00";
      this.total_time = "0:00";
      this.progress_width = 0;
      this.playing = false;
      this.loading = false;
      this.$store.commit('setPlaying', null);
      this.now_playing = null;
    },
    audioSeeking() {
      this.loading = false;
      this.current_time = this.formatTime(this.$refs.audio.currentTime);
    },
    audioSeeked(event) {
      this.audioPaused();
    },
    audioUpdateProgress() {
      if (this.$refs.audio) {
        const current = this.$refs.audio.currentTime;
        const percent = (current / this.$refs.audio.duration) * 100;
        this.progress_width = percent;
        this.current_time = this.formatTime(current);
      }
    },
    audioCanPlay(event) {
      this.audioPlayed();
    },
    audioReplay(event) {
      let $this = event;
      this.triggerMusicHit($this, $this.$store.state.current_playing);
      var audio = document.getElementById('audioPlayer');
      audio.currentTime = 0;
      setTimeout(function () {
        audio.pause();
      }, 50);
      setTimeout(function () {
        audio.play();
      }, 1000);
    },
    setCurrentTime(event) {
      const seekerTimePart = this.$refs.audio.duration / event.target.offsetWidth;
      const seek_time = seekerTimePart * (event.offsetX + 9);
      // this.$refs.audio.currentTime = seek_time;
      // this.current_time = this.formatTime(seek_time);
      // this.progress_width = (event.offsetX / event.target.offsetWidth ) * 100;
      // this.$refs.audio.pause();
      // this.playing = false;
      // this.$refs.audio.play();
      // this.playing = true;
      this.audioPaused();
      this.current_time = this.formatTime(seek_time);
      this.progress_width = (event.offsetX / event.target.offsetWidth) * 100;
      this.currentTime = seek_time;
      this.$refs.audio.currentTime = parseFloat(seek_time);
    },
    async getMusic(id) {
      const URL = `${this.base_url}api/v1/home/music/${id}`
      await axios.get(URL)
      .then(({ data }) => {
        const { cover_photo, img_file_ext } = data
        data.album_art = `${cover_photo}.${img_file_ext}`
        return data
      })
    },
    async getPlaylistMusic(playlist_id) {
      let list = []
      await axios.get(`${this.base_url}api/v1/playlist/${playlist_id}`)
        .then(({ data }) => {
          data.data.forEach(song => {
            song.album_art = `${song.cover_photo}.${song.img_file_ext}`
          })
          list = data.data
        })

      return list
    },
    randomizeSource (source) {
      const length  = source.length - 1
      for(let i = length; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = source[i]
        source[i] = source[j]
        source[j] = temp
      }
      return source
    },
    async setPlayerList (music_id) {
      let source = []
      if (this.$store.state.play_from_list.list === 'likes') {
        source = this.$store.state.likes
      } else if (this.$store.state.play_from_list.list === 'favorites') {
        source = this.$store.state.favorites
      } else {
        const { list } = this.$store.state.play_from_list
        const { id } = this.$store.state.playlists.find(playlist => list === playlist.name)
        source = await this.getPlaylistMusic(id)
      }
      const current = source.findIndex(src => src.id === music_id)
      if (this.shuffle) {
        source = this.randomizeSource(source)
        this.listSource = source
      } else {
        this.listSource = source
      }
      while (source.length < 3) {
        source.push(source[0])
      }
      const prev = (current === 0) ? source[source.length - 1] : source[current - 1]
      const next = (current === (source.length - 1)) ? source[0] : source[current + 1]
      const set = [prev, source[current], next]
      set.forEach(s => {
        s.audio = `${s.filename}.${s.music_file_ext}`
      })
      this.$store.state.random_music = set
      if (prev === source[0]) {
        this.listNav.prev = source[source.length - 1]
      } else if (prev === source[1]) {
        this.listNav.prev = source[0]
      } else {
        this.listNav.prev = source[current - 2]
      }
      if (next === source[source.length - 1]) {
        this.listNav.next = source[0]
      } else if (next === source[source.length - 2]) {
        this.listNav.next = source[source.length - 1]
      } else {
        this.listNav.next = source[current + 2]
      }
      this.$emit('playlistPN', {
        p: prev,
        n: next
      })
    },
    getRandom(direction) {
      if (this.$store.state.play_from_list.fromList) {
        if (direction == 'prev') {
          this.now_playing = this.$store.state.random_music[0]
          this.$store.state.random_music[0] = this.listNav.prev
          this.$store.state.random_music[2] = this.$store.state.random_music[1]
          this.$store.state.random_music[1] = this.now_playing
          this.$store.state.current_playing = this.now_playing
        } else {
          console.log(this.now_playing.title)
          if (this.loop) {
            this.now_playing = this.$store.state.random_music[2]
            this.$store.state.random_music[0] = this.$store.state.random_music[1]
            this.$store.state.random_music[1] = this.now_playing
            this.$store.state.random_music[2] = this.listNav.next
            this.$store.state.current_playing = this.now_playing
          } else if (this.now_playing.id !== this.listSource[this.listSource.length -1].id) {
            this.now_playing = this.$store.state.random_music[2]
            this.$store.state.random_music[0] = this.$store.state.random_music[1]
            this.$store.state.random_music[1] = this.now_playing
            this.$store.state.random_music[2] = this.listNav.next
            this.$store.state.current_playing = this.now_playing
          } else {
            this.$store.commit('setPlaying', null)
          }
        }
      } else {
        axios.get(this.base_url + 'api/v1/home/random/1')
          .then(response => {
            if (direction == 'prev') {
              this.now_playing = this.$store.state.random_music[0];
              this.$store.state.random_music[0] = response.data.data[0];
              this.$store.state.random_music[1] = this.now_playing;
              this.$store.state.random_music[2] = this.$store.state.current_playing;
              this.$store.state.current_playing = this.now_playing;
            }

            if (direction == 'next') {
              this.now_playing = this.$store.state.random_music[2];
              this.$store.state.random_music[0] = this.$store.state.current_playing;
              this.$store.state.random_music[1] = this.now_playing;
              this.$store.state.random_music[2] = response.data.data[0];
              this.$store.state.current_playing = this.now_playing;
            }
          })
          .catch(error => console.log(error));
      }
    },
    formatTime(time) {
      var min = Math.floor(time / 60);
      var sec = Math.floor(time % 60);
      return "".concat(min, ":").concat(sec < 10 ? "0".concat(sec) : sec);
    },
    triggerMusicHit(event, music) {
      let $this = event;
      let musicId = (music.id) ? music.id : music.music_id;
      let user_agent = navigator.userAgent;
      let hit = {
        "music_id": musicId,
        "user_agent": user_agent
      };
      axios.post($this.base_url + 'api/v1/hit', hit)
        .then(response => {
          if ($this.$store.state.top_requests_list.length != 0) {
            let top_requests_list_music = $this.$store.state.top_requests_list.filter(top_request => {
              if (!music || top_request.music_id == musicId) {
                top_request.hit_count++;
              }
            });
          }
        })
        .catch(error => console.log(error));
    }
  },
  watch: {
    current_playing(newVal, oldVal) {
      var $this = this;
      if (newVal !== null || newVal === oldVal) {
        if (newVal.img_file_ext) {
          const album_art = `${newVal.cover_photo}.${newVal.img_file_ext}`
          newVal.album_art = album_art
        }

        this.now_playing = newVal;
        this.playCurrent(newVal);
      }
      setTimeout(function () {
        if (isNaN(parseFloat($this.progress_width))) {
          var $this1 = $this;

          setTimeout(function () {
            $this1.getRandom('next');
          }, 1000);
        }
      }, 6000);
    },
    progress_width(newVal, oldVal) {
      if (newVal == 100) {
        var $this = this;

        setTimeout(function () {
          $this.getRandom('next');
        }, 1000);
      }
    },
    open_player(newVal, oldVal) {
      this.player_open = newVal;
    },
    player_open(newVal, oldVal) {
      if (newVal == true && this.$store.state.play_trigger_component == "transition-group") {
        this.player_open = false;
      } else {
        this.$store.commit("setPlayTriggerComponent", null);
      }
    }
  },
  created() {
    this.base_url = this.$store.state.base_url;
  }
}
</script>
