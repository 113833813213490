<template>
  <div class="level mt-4 px-3">
    <div class="level-left">
      <div class="level-item">
        <span class="is-inline-block mr-4">{{ `Page ${ activePage } of ${ totalPages }` }}</span>
      </div>
      <div class="level-item">
        <span class="is-inline-block mr-4">{{ `Total Songs: ${ pager }` }}</span>
      </div>
      <div class="level-item">
        <span :class="['playlist-controls', { 'playlist-control-on ': loop }]" v-show="showControls" @click="$store.commit('toggleLoop')">
          <span class="is-inline-block mr-2">
            loop
          </span>
          <i class="fa fa-rotate-right" />
        </span>
      </div>
      <div class="level-item">
        <span :class="['playlist-controls', { 'playlist-control-on ': shuffle }]" v-show="showControls && loop" @click="$store.commit('toggleShuffle')">
          <span class="is-inline-block mr-2">
            Shuffle
          </span>
          <i class="fa fa-random" />
        </span>
      </div>
    </div>
    <div class="level-right">
      <paginate
      v-show="totalPages > 1"
      :page-count="totalPages"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'table-pagination'"
      :page-link-class="'page-number'"
      :prev-link-class="'prev-btn'"
      :next-link-class="'next-btn'"
      :break-view-link-class="'break-view'"
      :active-link-class="'active-page'"
      :active-class="'active-page'"
      :force-page="activePage"
      :hide-prev-next="true"
      :click-handler="getPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['pager', 'activePage', 'showControls'],
  computed: {
    totalPages () {
      return Math.ceil(this.pager / this.perPage)
    },
    perPage () {
      return this.$store.state.perPage
    },
    shuffle () {
      return this.$store.state.shuffle
    },
    loop () {
      return this.$store.state.loop
    }
  },
  methods: {
    getPage (pageNumber) {
      if (this.activePage !== pageNumber) {
        this.$emit('get-page', pageNumber)
      } 
    }
  }
}
</script>
<style lang="scss" scoped>
  .playlist-controls {
    cursor: pointer;
    transition: 0.4s linear;
    &.playlist-control-on {
      color: #4099FF;
    }
  }
</style>
