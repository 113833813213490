<template>
<footer class="mt-5" v-bind:style="{ backgroundImage: 'url(' + base_url + 'images/footer-bg2.png'+')' }">

    <div class="row">
        <div style="padding: 30px" class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <h3 class="footer-title"><span>New release</span></h3>
            <ul style="padding-left: 0">
                <li class="mb-2" v-for="music in latest" v-bind:key="music.id">
                    <div class="footer-li-img">
                        <img @click="playMusic(music, 'footer')" class="rounded" :src="`${ base_url }cover_photos/${ music.album_art }`">
                    </div>
                    <div class="footer-li-description">
                        <span class="header truncated-text-line" style="width: 150px">
                            {{ music.title }}
                        </span>
                        <span class="sub-header">
                            {{ music.artist }}
                        </span>
                    </div>
                </li>
            </ul>

        </div>
        <div style="padding: 30px" class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <h3 class="footer-title"><span>Top Artists</span></h3>
            <ul style="padding-left: 0">
                <li class="mb-2" v-for="music in top" v-bind:key="music.id">
                    <div class="footer-li-img">
                        <img @click="playMusic(music, 'footer')" class="rounded" :src="`${ base_url }cover_photos/${ music.album_art }`">
                    </div>
                    <div class="footer-li-description">
                        <span class="header truncated-text-line" style="width: 150px">
                            {{ music.artist }}
                        </span>
                        <span class="sub-header">
                            {{ music.title }}
                        </span>
                    </div>
                </li>
            </ul>

        </div>
        <div style="padding: 30px" class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <h3 class="footer-title"><span>Comments</span></h3>
            <ul style="padding-left: 0">
                <li class="mb-2" v-for="music_comment in comments" v-bind:key="music_comment.id">
                    <div class="footer-li-img">
                        <!-- <img @click="playMusic(music_comment, 'footer')" class="rounded" :src="`${ base_url }cover_photos/${ music_comment.album_art }`"> -->
                        <img @click="gotoComments(music_comment.id)" class="rounded" :src="`${ base_url }cover_photos/${ music_comment.album_art }`">
                    </div>
                    <div class="footer-li-description">
                        <span class="header" style="cursor: pointer" @click="gotoComments(music_comment.id)">
                            {{ music_comment.artist }}
                        </span>
                        <span class="sub-header">
                            {{ checkComments(music_comment.comments) }}
                        </span>
                    </div>
                </li>
            </ul>

        </div>
    </div>
    <div style="width: 100%; text-align: right; padding: 10px 75px 10px 10px;">
        <h4 class="copyright-text">DON'T FORGET TO LAUNCH YOUR MUSIC!</h4>
        <ul class="footer-ul" :class="{ 'pull-left': this.window.width > 1145 }" style="padding-left: 0px;">
            <li>
                <router-link @click.native="gotoTop" :to="{ name: 'index' }">HOME</router-link>
            </li>
            <li>
                <router-link @click.native="gotoTop" :to="{ name: 'bulletin-board' }">BULLETIN BOARD</router-link>
            </li>
            <li>
                <router-link @click.native="gotoTop" :to="{ name: 'top-requests' }">TOP REQUESTS</router-link>
            </li>
            <li>
                <router-link @click.native="gotoTop" :to="{ name: 'about-us' }">ABOUT US</router-link>
            </li>
            <li v-if="isLoggedIn && !isAdmin">
                <router-link @click.native="gotoTop" :to="{ name: 'my-account' }">MY ACCOUNT</router-link>
            </li>
            <li v-if="!isLoggedIn">
                <router-link @click.native="gotoTop" :to="{ name: 'sign-in' }">SIGN IN</router-link>
            </li>
            <li v-if="isLoggedIn && isAdmin">
                <router-link @click.native="gotoTop" :to="{ name: 'dashboard' }">DASHBOARD</router-link>
            </li>
            <li v-if="isLoggedIn"><a @click="logout">SIGN OUT</a></li>
        </ul>
        <ul class="footer-ul pull-right">
            <li>
                <a href="mailto:soundlaunch@soundlaunch.com" data-toggle="modal" data-target="#contactUsModal" @click.prevent="contactUsEmail = 'soundlaunch@soundlaunch.com';  contactUsTitle = 'SoundLaunch.com'">SOUNDLAUNCH</a>
            </li>
            <li>
                <a href="mailto:comments@soundlaunch.com" data-toggle="modal" data-target="#contactUsModal" @click.prevent="contactUsEmail = 'comments@soundlaunch.com';  contactUsTitle = 'Comments'">COMMENTS</a>
            </li>
            <li>
                <a href="mailto:support@soundlaunch.com" data-toggle="modal" data-target="#contactUsModal" @click.prevent="contactUsEmail = 'support@soundlaunch.com';  contactUsTitle = 'Support'">SUPPORT</a>
            </li>
            <li>
                <a href="mailto:contactus@soundlaunch.com" data-toggle="modal" data-target="#contactUsModal" @click.prevent="contactUsEmail = 'contactus@soundlaunch.com';  contactUsTitle = 'Contact Us'">CONTACT US</a>
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
    <ContactUsModal :contactUsEmail="contactUsEmail" :contactUsTitle="contactUsTitle" />
</footer>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import ContactUsModal from './ContactUsModal';
import FloatingPlayer from './../components/FloatingPlayer.vue';

export default {
    components: {
        ContactUsModal
    },
    data() {

        return {
            latest: [],
            top: [],
            comments: [],
            contactUsEmail: '',
            contactUsTitle: '',
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }

    },
    methods: {
        playMusic(music, playTriggerComponent = null) {
            if (playTriggerComponent != null || playTriggerComponent != "") {
                this.$store.commit("setPlayTriggerComponent", playTriggerComponent);
            }

            if (this.current_playing != null && music.id == this.current_playing.id) {
                FloatingPlayer.methods.audioReplay(this);
            }
            this.$store.commit('setPlaying', music);
            // this.gotoTop();

            // let user_agent = navigator.userAgent;
            // let hit = {
            //     "music_id" : music.id,
            //     "user_agent" : user_agent
            // };

            // axios.post(this.base_url+'api/v1/hit', hit )
            //         .then(response=>{
            //         })
            //         .catch(error=>console.log(error));
        },
        fetchLatest() {

            axios.get(this.base_url + 'api/v1/home/latest')
                .then(response => {
                    this.latest = response.data.data;
                })
                .catch(error => console.log(error));

        },
        fetchTopArtists() {

            axios.get(this.base_url + 'api/v1/home/top')
                .then(response => {
                    this.top = response.data.data;
                })
                .catch(error => console.log(error));

        },
        fetchMusicComments() {

            axios.get(this.base_url + 'api/v1/home/comments')
                .then(response => {
                    this.comments = response.data.data;
                })
                .catch(error => console.log(error));

        },
        checkComments(music_comments) {
            return music_comments.length == 0 ? "No comments yet" : music_comments[0].body.substr(0, 10) + "...";
        },
        gotoComments(id) {
            this.$router.push('/bulletin-board?musicId=' + id);
            // this.gotoTop();
        },
        logout() {

            this.$store.dispatch('logout');

        },
        gotoTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    created() {
        this.fetchLatest();
        this.fetchTopArtists();
        this.fetchMusicComments();

        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'loginStatus',
            isAdmin: 'adminRights',
            base_url: 'baseUrl',
            current_playing: 'currentPlaying',
            top_requests: 'topRequestsList',
        })
    },
    watch: {
        current_playing(newVal, oldVal) {
            if (newVal !== null || newVal == oldVal) {
                this.selected = newVal;
            }

            // if (this.$store.state.play_trigger_component != null || this.$store.state.play_trigger_component != "") {
            //     this.playMusic(newVal, this.$store.state.play_trigger_component);
            // } else {
            //     this.playMusic(newVal);
            // }
        },
        open_player(newVal, oldVal) {
            this.$store.commit('setOpenPlayer', newVal);
            // console.log('Home component open_player watcher newVal:', newVal);
            // console.log('Home component open_player watcher open_player state:', this.$store.state.open_player);
        }
    },
}
</script>

<style>
footer {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.19) !important;
    background-color: rgba(50, 50, 50, .5);
    padding-top: 10px;
    padding-bottom: 60px;
}

.footer-title {
    color: #fff;
    position: relative;
    margin: 0 0 0.4em 0;
    border-bottom: 4px solid;
    padding-bottom: 0.4em;
}

footer ul {
    list-style-type: none;
}

.footer-ul li {
    display: inline;
}

.footer-ul li+li:before {
    content: " / ";
    padding: 0 5px;
    color: white !important;
    font-weight: 600;
}

.footer-ul li a {
    /* color: #717121 !important; */
    color: #ebeea7 !important;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.footer-li-img {
    display: table-cell;
}

.footer-li-img img {
    width: 80px;
    height: 80px;
}

.footer-li-description {
    padding-left: 10px;
    display: table-cell;
    padding-top: 0;
    vertical-align: top;
    line-height: 1.15;
}

.footer-li-description .header {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #717121;
}

.footer-li-description .sub-header {
    font-weight: 500;
    color: white;
}

.copyright-text {
    color: #fff;
    font-weight: 600;
}

_:-ms-lang(x),
footer {
    background-image: none !important;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.19) !important;
    background-color: rgba(50, 50, 50, .5);
    padding-top: 10px;
    padding-bottom: 60px;
}
</style>
