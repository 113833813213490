<template>
  <div class="home mt-5">
    <section class="text-center container" v-if="this.selected">
        <div class="row">
          <!-- Center -->
          <div class="col-sm-12 col-x-12 col-md-4 order-md-2 col-sm-1 home-player-center" @mouseover="openPlayer" @mouseout="closePlayer">
            <transition name="fade">
              <div class="music-panel panel" @click="playMusic(selected)" v-if="random_select.length!=0 && !fade_center">
                <div style="position: relative;">
                  <div v-if="isLoggedIn" class="song-btns">
                    <div>
                      <div class="action-btn" @click="like(selected.id, random_select)"><i :class="['icon-liked-solid', { 'liked-active': random_select[1].like }]"></i></div>
                      <div class="action-btn" @click="fave(selected.id, random_select)"><i :class="['icon-heart-solid', { 'favorite-active': random_select[1].favorite }]"></i></div>
                    </div>
                    <div class="action-btn" @click="onPlaylist(selected.id)"><i class="icon-add-to-playlist"></i></div>
                  </div>
                  <img style="width: 100%; height: 300px;" class="rounded" :src="`${ base_url }cover_photos/${ selected.album_art }`">
                </div>
                <span class="fa fa-play preview-play-big"></span>
                <h3 class="mt-3 text-dark">{{selected.title}}</h3>
                <h5 class="">{{selected.artist}}</h5>
              </div>
            </transition>
          </div>
          <!-- Left -->
          <div class="col-xs-6 col-sm-6 col-md-4 order-md-1">
            <div class="music-panel panel" @click="getRandom('prev')" v-if="random_select[0] && !($store.state.play_from_list.fromList)">
              <div style="position: relative;">
                <div class="song-btns" v-if="isLoggedIn">
                  <div>
                    <div class="action-btn" @click="like(random_select[0].id, random_select)"><i :class="['icon-liked-solid', { 'liked-active': random_select[0].like }]"></i></div>
                    <div class="action-btn" @click="fave(random_select[0].id, random_select)"><i :class="['icon-heart-solid', { 'favorite-active': random_select[0].favorite }]"></i></div>
                  </div>
                  <div class="action-btn" @click="onPlaylist(random_select[0].id)"><i class="icon-add-to-playlist"></i></div>
                </div>
                <img style="width: 100%; height: 200px; margin-top: 50px;" class="rounded" :src="`${ base_url }cover_photos/${ random_select[0].album_art }`">
              </div>
              <span class="fa fa-backward preview-backward"></span>
              <h3 class="mt-3 text-dark">{{random_select[0].title}}</h3>
              <h5 class="">{{random_select[0].artist}}</h5>
            </div>
            <div class="music-panel panel" @click="getRandom('prev')" v-else>
              <div style="position: relative;">
                <div class="song-btns" v-if="isLoggedIn">
                  <div>
                    <div class="action-btn" @click="like(PN.p.id, $store.state.random_music)"><i :class="['icon-liked-solid', { 'liked-active': PN.p.like }]"></i></div>
                    <div class="action-btn" @click="fave(PN.p.id, $store.state.random_music)"><i :class="['icon-heart-solid', { 'favorite-active': PN.p.favorite }]"></i></div>
                  </div>
                  <div class="action-btn" @click="onPlaylist(PN.p.id)"><i class="icon-add-to-playlist"></i></div>
                </div>
                <img style="width: 100%; height: 200px; margin-top: 50px;" class="rounded" :src="`${ base_url }cover_photos/${ PN.p.album_art }`">
              </div>
              <span class="fa fa-backward preview-backward"></span>
              <h3 class="mt-3 text-dark">{{PN.p.title}}</h3>
              <h5 class="">{{PN.p.artist}}</h5>
            </div>
          </div>
          <!-- Right -->
          <div class="col-xs-6 col-sm-6 col-md-4 order-md-3">
            <div class="music-panel panel" @click="getRandom('next')" v-if="random_select[2] && !($store.state.play_from_list.fromList)">
              <div style="position: relative;">
                <div class="song-btns" v-if="isLoggedIn">
                  <div>
                    <div class="action-btn" @click="like(random_select[2].id, random_select)"><i :class="['icon-liked-solid', { 'liked-active': random_select[2].like }]"></i></div>
                    <div class="action-btn" @click="fave(random_select[2].id, random_select)"><i :class="['icon-heart-solid', { 'favorite-active': random_select[2].favorite }]"></i></div>
                  </div>
                  <div class="action-btn" @click="onPlaylist(random_select[2].id)"><i class="icon-add-to-playlist"></i></div>
                </div>
                <img style="width: 100%; height: 200px; margin-top: 50px;" class="rounded" :src="`${ base_url }cover_photos/${ random_select[2].album_art }`">
              </div>
              <span class="fa fa-forward preview-forward"></span>
              <h3 class="mt-3 text-dark">{{random_select[2].title}}</h3>
              <h5 class="">{{random_select[2].artist}}</h5>
            </div>
            <div class="music-panel panel" @click="getRandom('next')" v-else>
              <div style="position: relative;">
                <div class="song-btns" v-if="isLoggedIn">
                  <div>
                    <div class="action-btn" @click="like(PN.n.id, $store.state.random_music)"><i :class="['icon-liked-solid', { 'liked-active': PN.n.like }]"></i></div>
                    <div class="action-btn" @click="fave(PN.n.id, $store.state.random_music)"><i :class="['icon-heart-solid', { 'favorite-active': PN.n.favorite }]"></i></div>
                  </div>
                  <div class="action-btn" @click="onPlaylist(PN.n.id)"><i class="icon-add-to-playlist"></i></div>
                </div>
                <img style="width: 100%; height: 200px; margin-top: 50px;" class="rounded" :src="`${ base_url }cover_photos/${ PN.n.album_art }`">
              </div>
              <span class="fa fa-forward preview-forward"></span>
              <h3 class="mt-3 text-dark">{{PN.n.title}}</h3>
              <h5 class="">{{PN.n.artist}}</h5>
            </div>
          </div>
        </div>
    </section>
    <section v-else>
      <div class="row">
        <div class="col">
          <div class="text-center mt-3">
            <h2 class="text-dark"><i class="fa fa-frown-o"></i> No matches found.</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="text-center mt-5">
      <div class="music-section">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input v-model="search_val" @input="searchMusic" class="form-control" name="search" placeholder="Search title, artist name or genre ...">
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3 text-center">
          <ul class="pagination justified-pagination">
            <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
              <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.prev_page)"><i class="fa fa-backward"></i></a>
            </li>
            <li class="page-item disabled">
              <a class="page-link text-dark" href="#">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} songs</a>
            </li>
            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
              <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.next_page)"><i class="fa fa-forward"></i></a>
            </li>
          </ul>
        </div>

        <div v-if="musics.length > 0">
          <div v-for="(musics2, index) in groupedMusic" v-bind:key="index" class="row mb-3">
            <div v-for="music in musics2" v-bind:key="music.id" class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div class="music-panel panel" @click="playMusic(music)">
                <div class="home-album-art" style="position: relative;margin: auto;">
                  <div v-if="isLoggedIn" class="song-btns">
                    <div>
                      <div class="action-btn" @click="like(music.id, musics)"><i :class="['icon-liked-solid', { 'liked-active': music.like }]"></i></div>
                      <div class="action-btn" @click="fave(music.id, musics)"><i :class="['icon-heart-solid', { 'favorite-active': music.favorite }]"></i></div>
                    </div>
                    <div class="action-btn" @click="onPlaylist(music.id)"><i class="icon-add-to-playlist"></i></div>
                  </div>
                  <img class="rounded home-album-art" :src="`${ base_url }cover_photos/${ music.album_art }`">
                </div>
                <span class="fa fa-play preview-play"></span>
                <h4 class="mt-3 home-music-title text-dark">{{music.title}}</h4>
                <h6 class="home-music-artist">{{music.artist}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-dark">No results found.</p>
        </div>

      </div>
        <div class="mt-3 mb-3 text-center">
          <ul class="pagination justified-pagination">
            <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
              <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.prev_page)"><i class="fa fa-backward"></i></a>
            </li>
            <li class="page-item disabled">
              <a class="page-link text-dark" href="#">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} songs</a>
            </li>
            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
              <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.next_page)"><i class="fa fa-forward"></i></a>
            </li>
          </ul>
        </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FloatingPlayer from './../components/FloatingPlayer.vue'

export default {
  props: ['likes', 'favorites', 'PN'],
  data() {
    return {
      musics: [],
      playlist: [],
      playbutton: false,
      pagination: {},
      search_val: "",
      archive_random_select: [],
      random_select: [],
      selected: null,
      fade_center: false,
      samplekey: 1
    }
  },
  computed: {
    groupedMusic() {
      return _.chunk(this.musics, 3);
    },
    ...mapGetters({
      user: 'userValues',
      axios_config: 'axiosConfig',
      base_url: 'baseUrl',
      current_playing: 'currentPlaying',
      open_player: 'openPlayer',
      top_requests: 'topRequestsList',
      isLoggedIn: "loginStatus",
      isAdmin: "adminRights",
      base_url: "baseUrl"
    }),
    ...mapState(['top_requests_list', 'random_music']),
  },
  methods: {
    like(id, src) {
   var isShow = true
      const index = src.findIndex(item => item.id === id)
      if (src[index].like) {
        src[index].like = false
      } else {
        
        // if(this.$store.state.likes.length >= 5){
        //    isShow = false
        //   alert("You exceed the limit")
        // }else{
        //   src[index].like = true
        // }
         src[index].like = true
      }
      if(isShow)
      this.$emit('like', id)
    },
    fave(id, src) {
      var isShow = true
      const index = src.findIndex(item => item.id === id)
      if (src[index].favorite) {
        src[index].favorite = false
      } else {
        //  if(this.$store.state.favorites.length >= 5){
        //    isShow = false
        //   alert("You exceed the limit")
        // }else{
        //   src[index].favorite = true
        // }       
         src[index].favorite = true
      }
       if(isShow)
      this.$emit('favorite', id)
    },
    onPlaylist(id) {
      this.$emit('openPlaylistModal', id)
    },
    fetchMusic(page_url = "") {
      console.log("Fetch Music")
      let vm = this;
      let url = this.base_url + "api/v1/music/all";
      if (page_url !== "") {
        url = page_url;
      }
      if (this.search_val !== "") {
        if (page_url !== "") {
          url += "&search=" + this.search_val;
        } else {
          url += "?search=" + this.search_val;
        }
      }

      axios.get(url)
        .then(response => {
          console.log(response)
          this.musics = this.attachLikesFaves(response.data.data)
          this.makePagination(response.data.meta, response.data.links)
        })
        .catch(error => console.log(error))
    },
    attachLikesFaves(d) {
      d.forEach(item => {
        item.like = this.likes.some(id => id === item.id)
      })
      d.forEach(item => {
        item.favorite = this.favorites.some(id => id === item.id)
      })
      return d
    },
    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page: links.next,
        prev_page: links.prev,
        from: meta.from,
        to: meta.to,
        total: meta.total
      }
      this.pagination = pagination;
    },
    playMusic(music, playTriggerComponent = null) {
      console.log("Play Music")
      this.random_select[0] = this.$store.state.current_playing;
      if (playTriggerComponent != null || playTriggerComponent != "") {
        this.$store.commit("setPlayTriggerComponent", playTriggerComponent);
        
      }
      if (music.id == this.current_playing.id) {
        FloatingPlayer.methods.audioReplay(this);
      }
      this.$store.commit('setPlaying', music);
      this.$store.commit('PlayFromList', { play: false, list: '', music: {} });

      this.gotoTop();
      this.fade_center = true;
      // this.player_open = true;
      setTimeout(function () {
        this.fade_center = false;
      }.bind(this), 1000);
      //TODO MAKE THIS BACK
      let user_agent = navigator.userAgent;
      let hit = {
        "music_id": music.id
      };

       

      //Log it for stats
      axios.post(this.base_url + 'api/v1/hit', hit)
        .then(response => {
          console.log('music', music);
        })
        .catch(error => console.log(error));

      this.player_open = false;
    },
    gotoTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    showPlayButton(event) {
      let thisImg = $(event.target);
      $(thisImg).next('span').removeClass('hidden');
    },
    hidePlayButton(event) {
      let thisImg = $(event.target);
      $(thisImg).next('span').addClass('hidden');
    },
    searchMusic() {
      setTimeout(function () {
        this.fetchMusic();
      }.bind(this), 1000);
    },
    fetchRandomFeatured(count = 3) {
      axios.get(this.base_url + 'api/v1/home/random/' + count)
        .then(response => {
          if (this.random_select.length == 0) {
            // this.random_select = response.data.data;
            this.$store.commit('setRandomMusic', response.data.data);
            const raw = this.$store.state.random_music
            this.random_select = this.attachLikesFaves(raw)
            this.selected = this.random_select[1];
            this.selected.like = this.likes.some(like => like.id === this.selected.id)
            this.selected.favorite = this.favorites.some(fave => fave.id === this.selected.id)

            this.$store.commit('setPlaying', this.selected);
          } else {
            this.random_select.push(response.data.data);
          }
        })
        .catch(error => console.log(error));
    },
    getRandom(direction) {
      console.log("GET RANDOM " + direction);
      if (!(this.$store.state.play_from_list.fromList)) {
        axios.get(this.base_url + 'api/v1/home/random/1')
          .then(response => {
            if (direction == 'prev') {
              this.selected = this.random_select[0];
              this.random_select[0] = this.$store.state.current_playing;
              this.random_select[1] = this.selected;
              this.$store.state.current_playing = this.selected;
            }
            if (direction == 'next') {
              this.selected = this.random_select[2];
              this.random_select[0] = this.$store.state.current_playing;
              this.random_select[1] = this.selected;
              this.random_select[2] = response.data.data[0];
              this.$store.state.current_playing = this.selected;
            }
          })
          .catch(error => console.log(error));
      }
    },
    fetchGenres() {
      axios.get(this.base_url + 'api/v1/genre')
        .then(response => {
          this.$store.commit('setGenres', response.data.data);
        })
        .catch(error => console.log(error));
    },
    openPlayer() {
      this.$store.state.open_player = true;
    },
    closePlayer() {
      this.$store.state.open_player = false;
    },
  },
  created() {
    this.fetchMusic();
    this.fetchRandomFeatured();
    this.fetchGenres();
  },
  watch: {
    current_playing(newVal, oldVal) {
      if (newVal !== null || newVal == oldVal) {
        this.selected = newVal;
      }
      this.$forceUpdate()
    },
    open_player(newVal, oldVal) {
      this.$store.commit('setOpenPlayer', newVal);
    }
  }
}
</script>

<style>
/* .preview-play{
    position: absolute;
    top: 20%;
    left: 5%;
    color: rgba(255,255,255,.8);
    font-size: 120px;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;
} */
.liked-active {
    color: rgb(0, 138, 223) !important;
}

.favorite-active {
    color: rgb(199, 0, 0) !important;
}

.preview-play-big::before,
.preview-play::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    bottom: 0;
}

.preview-play-big {
    position: absolute;
    top: 0;
    left: 0%;
    color: rgba(255, 255, 255, .8);
    font-size: 150px;
    width: calc(100% - 29px);
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;
    margin: 0 15px;
}

.preview-play {
    position: absolute;
    top: 0;
    left: 0%;
    color: rgba(255, 255, 255, .8);
    font-size: 14vh;
    width: calc(100% - 29px);
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;
    margin: 0 15px;
}

.preview-play-big:hover,
.preview-play:hover {
    opacity: 1;
}

.preview-forward {

    position: absolute;
    top: 20%;
    left: 0;
    color: rgba(255, 255, 255, .8);
    font-size: 120px;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;

}

.preview-forward:hover {
    opacity: 1;
}

.preview-backward {

    position: absolute;
    top: 20%;
    left: 0;
    color: rgba(255, 255, 255, .8);
    font-size: 120px;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    cursor: pointer;

}

.preview-backward:hover {
    opacity: 1;
}

.music-panel img {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.music-section {
    margin: 0 auto;
    width: 70%;
}

.home-player-center {
    height: 383.200px !important;
}
</style>
