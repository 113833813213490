<template>

    <div class="mb-3">
        <h1>Users Management</h1>
        <div class="card mt-3">
            <div class="card-body">

                <form class="form-signin">

                    <div class="form-group">
                        <input :disabled="view || edit" v-model="first_name" type="text" class="form-control" placeholder="First name" required="" autofocus="" autocomplete="off">
                        <p v-for="error in errors.first_name" class="text-danger" v-if="errors.first_name">{{ error }}</p>
                    </div>
                    <div class="form-group">
                        <input :disabled="view || edit" v-model="last_name" type="text" class="form-control" placeholder="Last name" required="" autofocus="" autocomplete="off">
                        <p v-for="error in errors.last_name" class="text-danger" v-if="errors.last_name">{{ error }}</p>
                    </div>
                    <div class="form-group">
                        <input :disabled="view || edit" v-model="email" type="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off">
                        <p v-for="error in errors.email" class="text-danger" v-if="errors.email">{{ error }}</p>
                    </div>
                    <div class="form-group">
                        <input :class="[{ 'hidden': view || edit },'']" :disabled="view || edit" v-model="password" type="password" name="password" id="password" class="form-control" placeholder="Password" required="" autofocus="" autocomplete="off">
                        <p v-for="error in errors.password" class="text-danger" v-if="errors.password">{{ error }}</p>
                    </div>
                    <div class="form-group">
                        <input :class="[{ 'hidden': view || edit },'']" :disabled="view || edit" v-model="password_confirmation" type="password" name="password_confirmation" id="password_confirmation" class="form-control" placeholder="Confirm Password" required="" autofocus="" autocomplete="off">
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-lg btn-default btn-block" ref="cancel" type="submit" @click.prevent="resetForm">Cancel</button>
                        </div>
                        <div class="col">
                            <button :disabled="view || edit" class="btn btn-lg btn-primary btn-block" ref="save_user" type="submit" @click.prevent="saveUser">Register</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <div class="card mt-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                        <ul class="pagination">
                            <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
                                <a class="page-link" href="#" @click.prevent="fetchUsers(pagination.prev_page)">Previous</a>
                            </li>
                            <li class="page-item disabled">
                                <a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a>
                            </li>
                            <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
                                <a class="page-link" href="#" @click.prevent="fetchUsers(pagination.next_page)">Next</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                        <div class="search-form form-group">
                            <span class="fa fa-search"></span>
                            <input type="text" v-model="search_val" @keyup="searchUser" name="search" id="search" placeholder="Search" class="form-control">

                        </div>
                    </div>
                </div>
                <div v-if="users.length!=0">
                    <div v-for="user in users" v-bind:key="user.id" class="card card-body mt-2">
                        <div class="btn-group dropleft">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="fa fa-ellipsis-v"></span>
                            </button>
                            <div class="dropdown-menu">
                            <a @click="updateUser(user)" href="javascript:;" class="dropdown-item update">Update</a>
                            <a @click="deleteUser(user.id)" href="javascript:;" class="dropdown-item">Delete</a>
                            <a @click="viewUser(user)" href="javascript:;" class="dropdown-item">View</a>
                            <a @click="updateStatus(user)" href="javascript:;" class="dropdown-item">{{ user.is_admin=="Y" ? "Unset as admin" : "Set as admin" }}</a>
                            <!-- <a @click="updateIsFeatured(music)" href="javascript:;" class="dropdown-item">{{ music.is_featured=="Y" ? "Set featured" : "Unset featured" }}</a> -->
                            </div>
                        </div>
                        <div class="">
                            <div class="user-description">
                                <h4 class="username">{{ user.first_name+' '+user.last_name }} <span style="font-size: 10px" class="badge badge-info" v-if="user.is_admin=='Y'">Admin</span></h4>
                                <p class="user-email">{{ user.email }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center mt-3">
                    <h4 class="text-muted"><i class="fa fa-frown-o"></i> No matches found.</h4>
                </div>
            </div>
        </div>



        <div class="modal " ref="updateModal">
            <div class="modal-dialog">
                <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" @click="closeUpdateModal" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="card-body">
                        <form class="form-signin">

                            <div class="form-group">
                                <input :disabled="view" v-model="update_first_name" type="text" class="form-control" placeholder="First name" required="" autofocus="" autocomplete="off">
                                <p v-for="error in update_errors.first_name" class="text-danger" v-if="update_errors.first_name">{{ error }}</p>
                            </div>
                            <div class="form-group">
                                <input :disabled="view" v-model="update_last_name" type="text" class="form-control" placeholder="Last name" required="" autofocus="" autocomplete="off">
                                <p v-for="error in update_errors.last_name" class="text-danger" v-if="update_errors.last_name">{{ error }}</p>
                            </div>
                            <div class="form-group">
                                <input :disabled="view" v-model="update_email" type="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off">
                                <p v-for="error in update_errors.email" class="text-danger" v-if="update_errors.email">{{ error }}</p>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-lg btn-default btn-block" type="submit" @click.prevent="closeUpdateModal">Cancel</button>
                                </div>
                                <div class="col">
                                    <button :disabled="view" class="btn btn-lg btn-primary btn-block" ref="update_user" type="submit" @click.prevent="saveUser">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>


                </div>
            </div>
        </div>


    </div>

</template>

<script>

import { mapGetters } from 'vuex';

export default {

    data(){
        return {
            users: [],
            pagination : {},
            search_val : "",
            first_name : "",
            last_name : "",
            email : "",
            password : "",
            password_confirmation : "",

            update_first_name : "",
            update_last_name : "",
            update_email : "",
            update_password : "",
            update_password_confirmation : "",
            edit : false,
            view : false,
            edit_user : null,
            errors: [],
            update_errors : [],

        }
    },
    methods : {
        redirect(){

            if( this.loggedin_user.is_admin != "Y" ){
                this.$router.push('/');
            }

        },
        fetchUsers( page_url= "" ){

            let vm = this;
            let url = this.base_url+"api/v1/users";

            if( page_url !=="" ){
               url = page_url;
            }

            if( this.search_val !== "" ) {
                url += "?search="+this.search_val;
            }

            axios.get(url, this.axios_config)
                .then(response=>{
                    this.users = response.data.data;
                    this.makePagination(response.data.meta, response.data.links);
                })
                .catch(error=>console.log(error));

        },
        makePagination( meta, links ){

            let pagination = {
                current_page    :   meta.current_page,
                last_page       :   meta.last_page,
                next_page       :   links.next,
                prev_page       :   links.prev,
            }

            this.pagination = pagination;

        },
        saveUser(){

            if( this.edit ){

                $(this.$refs.update_user).attr('disabled','disabled');
                $(this.$refs.update_user).text('Updating...');

                let formData = {
                    "id"            :   this.edit_user.id,
                    "first_name"    :   this.update_first_name,
                    "last_name"     :   this.update_last_name,
                    "email"         :   this.update_email,
                    "_method"       :   "put"
                }


                axios.post(this.base_url+'api/v1/user', formData, this.axios_config)
                        .then(response=>{

                            this.$swal({
                                type: 'success',
                                title: "User updated.",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(result=>{
                                this.closeUpdateModal();
                                this.fetchUsers();
                                $(this.$refs.update_user).removeAttr('disabled');
                                $(this.$refs.update_user).text('Update');
                            });
                        })
                        .catch(error=>{
                            $(this.$refs.update_user).removeAttr('disabled');
                            $(this.$refs.update_user).text('Update');
                            if(error.response.data){
                                this.errors = error.response.data.errors;
                            }
                            console.log(error)
                        });


            }else{

                if( this.password==this.password_confirmation){

                    $(this.$refs.save_user).attr('disabled','disabled');
                    $(this.$refs.save_user).text('Saving...');

                    let formData = {
                        "first_name"    :   this.first_name,
                        "last_name"     :   this.last_name,
                        "email"         :   this.email,
                        "password"      :   this.password,
                        "password_confirmation" : this.password_confirmation
                    }

                    axios.post(this.base_url+'api/v1/user', formData, this.axios_config)
                            .then(response=>{

                                this.$swal({
                                    type: 'success',
                                    title: "User registered.",
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then(result=>{
                                    this.resetForm();
                                    this.fetchUsers();
                                    $(this.$refs.save_user).removeAttr('disabled');
                                    $(this.$refs.save_user).text('Register');
                                });
                            })
                            .catch(error=>{
                                $(this.$refs.save_user).removeAttr('disabled');
                                $(this.$refs.save_user).text('Register');
                                if(error.response.data){
                                    this.errors = error.response.data.errors;
                                }
                                console.log(error)
                            });

                }

            }

        },
        updateUser( user ){

            this.update_first_name = user.first_name;
            this.update_last_name = user.last_name;
            this.update_email = user.email;
            this.edit = true;
            this.view = false;
            this.edit_user = user;

            $(this.$refs.updateModal).modal('show');

        },
        updateStatus ( user ){

            if( user.id == this.loggedin_user.id ){
                this.$swal({
                    type: 'error',
                    title: 'Oops...',
                    text: 'You cannot update your own admin rights!',
                })
            }else{

                this.$swal({
                title: 'Are you sure you want to update admin rights of this user?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!'
            }).then(result=>{
                if( result.value ){
                    let updater_rights = this.loggedin_user.is_admin;
                    user.is_admin = user.is_admin == "Y" ? "N" : "Y";

                    let formData = {
                        "user_id" : user.id,
                        "updater_rights" : updater_rights,
                        "user_admin_rights" : user.is_admin,
                        "_method"       :   "put"
                    }

                    axios.post(this.base_url+'api/v1/update-stat-user', formData, this.axios_config)
                    .then(response=>{

                        this.$swal({
                            type: 'success',
                            title: "User updated.",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(result=>{
                            this.resetForm();
                            this.fetchUsers();
                        });
                    })
                    .catch(error=>{

                        if(error.response.data){
                            this.errors = error.response.data.errors;
                        }
                        console.log(error)
                    });

                }
            })

            }

        },
        closeUpdateModal(){
            this.resetForm();
            $(this.$refs.updateModal).modal('hide');
        },
        deleteUser( id ){

            this.$swal({
                title: 'Are you sure you want to delete this user?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(result=>{
                if( result.value ){

                    let data = {
                        'user' : this.loggedin_user.is_admin,
                        'id' : id,
                        '_method' : 'delete'
                    };

                    axios.delete(this.base_url+'api/v1/user', { "params" : data } , this.axios_config)
                        .then(response=>{

                            this.$swal({
                                type: 'success',
                                title: "User deleted.",
                                showConfirmButton: false,
                                timer: 1500
                            }).then(result=>{
                                this.resetForm();
                                this.fetchUsers();
                            });

                        })
                        .catch(error=>console.log(error));

                }
            })



        },
        viewUser( user ){

            this.first_name = user.first_name;
            this.last_name = user.last_name;
            this.email = user.email;

            this.view = true;
        },
        resetForm(){
            this.first_name= "";
            this.last_name= "";
            this.email="";
            this.password = "";
            this.password_confirmation="";
            this.view = false;
            this.edit = false;
            this.errors =[];
            this.update_errors = [];
        },
        searchUser(){
            this.fetchUsers()
        }
    },
    created() {
        this.fetchUsers();
        this.redirect();
    },
    computed : {
        ...mapGetters( {
            loggedin_user : 'userValues',
            base_url : 'baseUrl',
            axios_config : 'axiosConfig',
        })
    }

}

</script>
