<template>

    <!-- <div class="d-flex justify-content-center">

        <h1>Sorry. Page not found.</h1>
        <button><router-link :to="{ name: 'index' }" class="nav-link">HOME</router-link></button>

    </div> -->

    <div class="row">
        <!-- <div class="col-sm-2 col-xs-12"></div>
        <div class="col-sm-8 col-xs-12">
            <div class="text-center">
                <h1>Sorry. Page not found.</h1>
                <br>
                <br>
                <router-link tag="button" :class="'btn btn-warning m-auto'" :to="{ name: 'index' }" class="nav-link">HOME</router-link>
            </div>
        </div>
        <div class="col-sm-2 col-xs-12"></div> -->
        <div class="col-sm-12">
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>Oops!</h1>
                    </div>
                    <h2>404 - Page not found</h2>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <router-link tag="a" :to="{ name: 'index' }" class="nav-link">Go To Homepage</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {

}

</script>

<style scoped>
    * {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

    body {
        padding: 0;
        margin: 0;
    }

    #notfound {
        position: relative;
        height: 65vh;
    }

    #notfound .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

    .notfound {
        max-width: 410px;
        width: 100%;
        text-align: center;
    }

    .notfound .notfound-404 {
        height: 280px;
        position: relative;
        z-index: -1;
    }

    .notfound .notfound-404 h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 230px;
        margin: 0px;
        font-weight: 900;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        background: url('/images/header-bg.jpg') no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover;
        background-position: center;
    }


    .notfound h2 {
        font-family: 'Montserrat', sans-serif;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
    }

    .notfound p {
        font-family: 'Montserrat', sans-serif;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .notfound a {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
        background: #727122;
        display: inline-block;
        padding: 15px 30px;
        border-radius: 40px;
        color: #fff;
        font-weight: 700;
        -webkit-box-shadow: 0px 4px 15px -5px #727122;
                box-shadow: 0px 4px 15px -5px #727122;
    }


    @media only screen and (max-width: 767px) {
        .notfound .notfound-404 {
            height: 142px;
        }
        .notfound .notfound-404 h1 {
            font-size: 112px;
        }
    }
</style>
