<template>
<div class="mb-3">
    <h1>Ads Management</h1>

    <div id="ad-form" class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">

                    <video ref="video" width="400" autoplay controls>
                        Your browser does not support HTML5 video.
                    </video>

                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <form name="ad_form" enctype="multipart/form-data" @submit.prevent>
                        <div class="form-group">
                            <label for="description">Description</label>
                            <textarea :disabled="view" v-model="description" id="description" name="description" rows="5" class="form-control"></textarea>
                            <p class="text-danger" v-if="errors.description">{{ errors.description[0] }}</p>
                        </div>
                        <div class="form-group" :class="[{ 'hidden': view || edit },'']">
                            <label for="file">File</label>
                            <input accept=".mov, .mp4" type="file" ref="file" @change="previewFile" name="file" class="form-control-file border">
                            <p class="text-danger" v-if="errors.file">{{ errors.file[0] }}</p>
                        </div>
                        <div class="form-group">
                            <label for="order">Order</label>
                            <input :disabled="view" v-model="order" type="number" name="order" id="order" min="0" max="100" class="form-control">
                            <p class="text-danger" v-if="errors.order">{{ errors.order[0] }}</p>
                        </div>
                        <div class="pull-right">
                            <button ref="cancel_save_ad" @click="resetForm" class="btn btn-primary">Clear</button>
                            <button ref="save_ad" v-if="!view" type="submit" @click="saveAd" class="btn btn-success">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3">
        <div class="card-body">

            <div class="row">
                <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                    <ul class="pagination unjustified-pagination">
                        <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
                            <a class="page-link" href="#" @click="fetchAds(pagination.prev_page)">Previous</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a>
                        </li>
                        <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
                            <a class="page-link" href="#" @click="fetchAds(pagination.next_page)">Next</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                    <form @prevent.submit="fetchAds(this.search)">
                        <div class="search-form form-group">
                            <span class="fa fa-search"></span>
                            <input type="text" @input="searchAds" v-model="search_val" name="search" id="search" placeholder="Search" class="form-control">

                        </div>
                    </form>
                </div>
            </div>

            <div v-for="ad in ads" v-bind:key="ad.id" class="card card-body mt-2">
                <div class="btn-group dropleft">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="fa fa-ellipsis-v"></span>
                    </button>
                    <div class="dropdown-menu">
                        <a @click="updateAd(ad)" href="javascript:;" class="dropdown-item update">Update</a>
                        <a @click="deleteAd(ad.id)" href="javascript:;" class="dropdown-item">Delete</a>
                        <a @click="viewAd(ad)" href="javascript:;" class="dropdown-item">View</a>
                        <!-- <a @click="toggleStatus(ad)" href="javascript:;" class="dropdown-item">View</a> -->
                    </div>
                </div>
                <h3>{{ ad.filename }}</h3>
                <p>{{ ad.description }}</p>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';

export default {

    data() {
        return {
            ads: [],
            ad_id: '',
            edit: false,
            view: false,
            pagination: {},
            search_val: "",
            file: null,
            description: "",
            order: 0,
            errors: []
        }
    },
    computed: {
        ...mapGetters({
            base_url: 'baseUrl',
            axios_config: 'axiosConfig',
            user: 'userValues'
        })
    },
    created() {
        this.fetchAds();
        this.redirect();
    },
    methods: {
        redirect() {

            if (this.user.is_admin != "Y") {
                this.$router.push('/');
            }

        },
        fetchAds(page_url = "") {

            let vm = this;
            let url = this.base_url + "api/v1/ads";

            if (page_url !== "") {
                url = page_url;
            }

            if (this.search_val !== "") {
                if (page_url !== "") {
                    url += "&search=" + this.search_val;
                } else {
                    url += "?search=" + this.search_val;
                }
            }

            axios.get(url, this.axios_config)
                .then(response => {
                    this.ads = response.data.data;
                    this.makePagination(response.data.meta, response.data.links);
                })
                .catch(error => console.log(error));
        },
        searchAds() {
            this.fetchAds();
        },
        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page: links.next,
                prev_page: links.prev,
            }

            this.pagination = pagination;
        },
        previewFile(event) {

            let temp_file = event.target.files[0];
            this.file = event.target.files[0];

            if (this.file.size > 300000000) {
                this.file = null;
                this.$refs.video.src = '';
                this.$refs.file.type = 'text';
                this.$refs.file.type = 'file';
                this.$swal({
                    'type': 'error',
                    'text': 'File too large. File should be less than 300 MB'
                })
            } else {
                let reader = new FileReader();

                // reader.addEventListener("load", function() {
                //     this.$refs.file_preview.src = reader.result;
                // }.bind(this),false);

                // reader.readAsDataURL( temp_file );

                this.$refs.video.src = URL.createObjectURL(this.file);
                this.$refs.video.load();
                this.$refs.video.play();
            }

        },
        saveAd() {

            let formData = new FormData();
            let message = "";
            formData.append('file', this.file);
            formData.append('order', this.order);
            formData.append('description', this.description);

            $(this.$refs.save_ad).attr('disabled', 'disabled');
            $(this.$refs.cancel_save_ad).attr('disabled', 'disabled');
            $(this.$refs.save_ad).text('Saving...');

            if (this.edit) {
                formData.append("id", this.ad_id);
                formData.append("_method", "put");
            }

            axios.post(this.base_url + 'api/v1/ads', formData, this.axios_config)
                .then(res => {

                    this.$swal({
                        // position: 'top-end',
                        type: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    }).then(result => {
                        $(this.$refs.save_ad).removeAttr('disabled');
                        $(this.$refs.cancel_save_ad).removeAttr('disabled');
                        $(this.$refs.save_ad).text('Save');
                        this.resetForm();
                        this.fetchAds();

                    });

                })
                .catch(error => {

                    if (error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                    $(this.$refs.save_ad).removeAttr('disabled');
                    $(this.$refs.cancel_save_ad).removeAttr('disabled');
                    $(this.$refs.save_ad).text('Save');
                    console.log(error)
                });

        },
        updateAd(ad) {

            this.edit = true;
            this.ad_id = ad.id;
            this.order = ad.order;
            this.description = ad.description;

            this.$refs.video.src = this.base_url + "ads/" + ad.video;
            this.$refs.video.load();
            this.$refs.video.play();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        },
        deleteAd(id) {
            this.$swal({
                title: 'Are you sure you want to delete this ad?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(result => {
                if (result.value) {

                    axios.post(`${ this.base_url }api/v1/ad/${id}`, {
                            _method: "delete"
                        }, this.axios_config)
                        .then(response => {
                            this.$swal({
                                // position: 'top-end',
                                type: 'success',
                                title: 'Ad deleted',
                                showConfirmButton: false,
                                timer: 1500
                            }).then(result => {
                                this.fetchAds();
                            });
                        })
                        .catch(error => console.log(error));

                }
            })
        },
        viewAd(ad) {

            this.$refs.video.src = this.base_url + "ads/" + ad.video;
            this.$refs.video.load();
            this.$refs.video.play();
            this.description = ad.description;
            this.order = ad.order;
            this.view = true;
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        resetForm() {

            this.view = false;
            this.edit = false;

            setTimeout(function () {
                this.$refs.video.src = '';
                this.$refs.file.type = "text";
                this.$refs.file.type = "file";
            }.bind(this), 1000);

            this.file = null;
            this.order = 0;
            this.description = "";

            this.errors = [];
        }
    }

}
</script>

<style>
video {
    width: 100%;
    height: auto;
}

.dropleft {
    position: absolute;
    right: 20px;
}

.dropdown-toggle::before {
    content: none !important;
}

.search-form {
    width: 100%;
    float: right;
    position: relative;
}

.fa-search {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>
