
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import {router} from './routes.js'
import {store} from './store.js'
import VueSweetalert2 from 'vue-sweetalert2'
import vClickOutside from 'v-click-outside'
import Paginate from 'vuejs-paginate'
import VueScrollTo from 'vue-scrollto'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.use(VueScrollTo, { offset: -200 })

Vue.directive('observe-visibility', ObserveVisibility)

Vue.component('paginate', Paginate)

import App from './App.vue'

import Client from './layouts/Client.vue'
import Admin from './layouts/Admin.vue'

Vue.component('default-layout', Client)
Vue.component('sidebar-layout', Admin)

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueSweetalert2)

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy)
Vue.use(vClickOutside)

const opts = {}

export default new Vuetify(opts)
export const bus = new Vue()

const app = new Vue({
    el: '#app',
    router,
    store,
    components : {
        App
    },
    refreshData : { enabled : false }
})
