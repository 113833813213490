<template>
  <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add to Playlist</p>
      </header>
      <section class="modal-card-body">
        <label for="">Select Option</label>
        <b-select v-model="currentPlaylist" class="custom-selection" :disabled="loading">
          <option disabled value="">Please select one</option>
          <option :value="0">Create New Playlist</option>
          <option v-for="(playlist, index) in playlists" :key="index" :value="playlist.id">
            {{ playlist.name }}
          </option>
        </b-select>
        <div style="margin-top: 10px;" v-show="currentPlaylist === 0">
          <label for="">Playlist Name</label>
          <input class="NewPlaylistNameBox" type="text" placeholder="New Playlist" v-model="newPlaylistName" :disabled="loading">
        </div>
      </section>
      <footer class="modal-card-button">
        <button class="button mr-3" type="button" @click="$emit('close')" :disabled="loading">Cancel</button>
        <button :class="['button', 'is-twitter', { 'is-loading': loading }]" @click="create" :disabled="!confirmable">Confirm</button>
      </footer>
      <subscribe-modal ref="subs" :showModal="subscribe" @close-subscribe-modal="close" />
  </div>
</template>

<script>
import Vue from 'vue'
import SubscribeModal from './SubscribeModal'
import { mapGetters } from "vuex";

export default {
  props: ['musicID'],
  components: {
    SubscribeModal
  },
  data: () => ({
    subscribe: false,
    currentPlaylist: 0,
    newPlaylistName: '',
    error: false,
    loading: false
  }),
  computed: {
     ...mapGetters({
      isLoggedIn: "loginStatus",
      isAdmin: "adminRights",
      base_url: "baseUrl"
    }),
    subscribed () {
      // Uncomment this once its implemented
      return this.$store.state.isSubscribed
    },
    playlists () {
      return this.$store.state.playlists
    },
    confirmable () {
      if (this.currentPlaylist !== 0) {
        return true
      } else if (this.newPlaylistName !== '') {
        return true
      } else {
        return false
      }
    },
    limits() {
      const limit = {}
      if (this.subscribed) {
        limit.onPlaylistLimit = false
        this.playlists.forEach(pl => {
          limit[`id${pl.id}`] = false
          console.log('00000' + limit[`id${pl.id}`])
        })
      } else {
        if(this.isAdmin){
         limit.onPlaylistLimit = false
        }else{
          limit.onPlaylistLimit = (this.playlists.length >= 5) ? true : false
        }
console.log('On Playlist Limit')
console.info(limit.onPlaylistLimit)

        this.playlists.forEach(pl => {
          if (pl.songs === null) {
            
            limit[`id${pl.id}`] = false
            console.log('1111' + limit[`id${pl.id}`])
          } else {
            if(this.isAdmin){
              limit[`id${pl.id}`] = false
              }else{
               limit[`id${pl.id}`] = (pl.songs.length >= 5) ? true : false
              }
           
            console.log('22222' + limit[`id${pl.id}`])
          }
        })
      }

      return limit
    }
  },
  methods: {
    create () {
      if (this.currentPlaylist) {
        this.addMusicToPlaylist()
      } else {
          this.createPlaylist()
        }
    },
    nameExists (name) {
      const expression = new RegExp(name, 'gi')
      const exists = this.playlists.some(playlist => expression.test(playlist.name))
      return exists
    },
    async createPlaylist () {
      if (this.limits.onPlaylistLimit) {
        this.subscribe = true
        this.$nextTick(() => this.$refs.subs.pay())
      } else {
        if (this.nameExists(this.newPlaylistName)) {
          this.$buefy.toast.open({
            message: `A Playlist named '${this.newPlaylistName}' already exists.`,
            type: 'is-danger'
          })
        } else {
          this.loading = true
          await this.$store.dispatch('createPlaylist', {
            name: this.newPlaylistName,
            id: this.musicID
          })
          .then(() => {
            this.loading = false
            this.$emit('close')
            this.$buefy.toast.open({
              message: 'Music added to playlist',
              type: 'is-primary',
              duration: 3000
            })
          })
        }
      }
    },
    async addMusicToPlaylist() {
      const playlist_id = this.currentPlaylist
      if (this.limits[`id${playlist_id}`]) {
        this.subscribe = true
        this.$nextTick(() => this.$refs.subs.pay())
      } else {
        this.loading = true
        const { songs } = this.playlists.find(playlist => playlist.id === playlist_id)
        if (songs.some(songID => this.musicID === songID)) {
          this.$emit('close')
          this.loading = false
          this.$buefy.toast.open({
            message: 'Music is already on the playlist',
            type: 'is-dark',
            duration: 3000
          })
        } else {
          songs.push(this.musicID)
          this.$store.dispatch('addMusicToPlaylist', {
            playlist_id: playlist_id,
            songs: songs
          })
          .then(() => {
            this.$emit('close')
            this.loading = false
            this.$buefy.toast.open({
              message: 'Music added to playlist',
              type: 'is-primary',
              duration: 3000
            })
          })          
        }
      }
    },
    close () {
      this.subscribe = false
      this.$emit('close')
    }
  },
}
</script>
