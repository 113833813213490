<template>

    <form class="form-signin">
        <div class="text-center mb-4">
            <h1 class="h3 mb-3 font-weight-normal">Forgot Password</h1>
        </div>

        <p>Please fill your e-email to send you a temporary password: </p>
        <p><b>Note:</b> <i>This will be replace your current password with temporary password.</i></p>
        <div class="form-group">
            <input v-model="email" type="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off">
            <p v-for="error in errors.email" class="text-danger" v-if="errors.email">{{ error }}</p>
        </div>

        <p v-if="error_msg != '' || error_msg != null" class="text-danger" style="font-weight: 700">{{ error_msg }}</p>

        <button class="btn btn-lg btn-primary btn-block" ref="forgot_password_btn" type="submit" @click.prevent="handleSubmit">Submit</button>
        <br>
        <!-- <p v-if="show_verification" id="verificationLinkContainer">
            Not receiving a temporary password reset e-mail? resend <a :href="`${ base_url }resend-submit`">here</a>.
        </p> -->
    </form>

</template>


<script>

import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                email : "",
                errors : [],
                error_msg: null,
            }
        },
        computed : {
            ...mapGetters({
                base_url : 'baseUrl'
            })
        },
        methods : {
            handleSubmit(e){
                e.preventDefault();

                let formData = {
                    "email": this.email,
                }

                this.error_msg = '';

                $(this.$refs.forgot_password_btn).attr('disabled','disabled');
                $(this.$refs.forgot_password_btn).text('Processing...');

                axios.post(this.base_url+'api/v1/submit-temp-password', formData).then(response => {
                    this.$swal({
                        type: 'success',
                        title: "We sent you a temporary password reset e-mail.",
                        text: "Please check your e-mail inbox or spam section to proceed.",
                        showConfirmButton: false,
                        timer: 4500
                    }).then(result=>{
                        $(this.$refs.forgot_password_btn).removeAttr('disabled');
                        $(this.$refs.forgot_password_btn).text('Submit');

                        this.show_verification = true;

                        this.resetForm();
                    });
                })
                .catch(error=>{
                    if (error.response.data) {
                        if (typeof error.response.data.errors != 'undefined') {
                            this.errors = error.response.data.errors;
                        } else {
                            this.errors = error.response.data.message;
                            this.error_msg = error.response.data.message;
                        }
                    }

                    console.log(error);
                    $(this.$refs.forgot_password_btn).removeAttr('disabled');
                    $(this.$refs.forgot_password_btn).text('Submit')

                });
            },
            resetForm() {
                this.email = "";
            }
        }
    }
</script>

