<template>

    <form class="form-signin">
        <div class="text-center mb-4">
            <h1 class="h3 mb-3 font-weight-normal">Register</h1>
        </div>

        <div class="form-group">
            <input v-model="first_name" type="text" class="form-control" placeholder="First name" required="" autofocus="" autocomplete="off">
            <p v-for="error in errors.first_name" class="text-danger" v-if="errors.first_name">{{ error }}</p>
        </div>
        <div class="form-group">
            <input v-model="last_name" type="text" class="form-control" placeholder="Last name" required="" autofocus="" autocomplete="off">
            <p v-for="error in errors.last_name" class="text-danger" v-if="errors.last_name">{{ error }}</p>
        </div>
        <div class="form-group">
            <input v-model="email" type="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off">
            <p v-for="error in errors.email" class="text-danger" v-if="errors.email">{{ error }}</p>
        </div>
        <div class="form-group">
            <input v-model="password" type="password" name="password" id="password" class="form-control" placeholder="Password" required="" autofocus="" autocomplete="off">
            <p v-for="error in errors.password" class="text-danger" v-if="errors.password">{{ error }}</p>
        </div>
        <div class="form-group">
            <input v-model="password_confirmation" type="password" name="password_confirmation" id="password_confirmation" class="form-control" placeholder="Confirm Password" required="" autofocus="" autocomplete="off">
        </div>

        <p v-if="registration_error_message != '' || registration_error_message != null" class="text-danger" style="font-weight: 700">{{ registration_error_message }}</p>

        <button class="btn btn-lg btn-primary btn-block" ref="register_user" type="submit" @click.prevent="handleSubmit">Register</button>
        <br>
        <p v-if="show_verification" id="verificationLinkContainer">
            Not receiving a verification e-mail? resend <a :href="`${ base_url }resend-verification`">here</a>.
        </p>
    </form>

</template>


<script>

import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                first_name : "",
                last_name : "",
                email : "",
                password : "",
                password_confirmation : "",
                errors : [],
                registration_error_message: null,
                show_verification: false,
            }
        },
        methods : {
            handleSubmit(e){
                e.preventDefault();

                if ( this.password==this.password_confirmation) {
                    let formData = {
                        "first_name"    :   this.first_name,
                        "last_name"     :   this.last_name,
                        "email"         :   this.email,
                        "password"      :   this.password,
                        "password_confirmation" : this.password_confirmation
                    }

                    this.registration_error_message = '';

                    $(this.$refs.register_user).attr('disabled','disabled');
                    $(this.$refs.register_user).text('Processing...');

                    axios.post(this.base_url+'api/v1/register', formData)
                        .then(response=>{

                            this.$swal({
                                type: 'success',
                                title: "We sent you a verification e-mail.",
                                text: "Please check your e-mail inbox or spam section to proceed.",
                                showConfirmButton: false,
                                timer: 4500
                            }).then(result=>{
                                $(this.$refs.register_user).removeAttr('disabled');
                                $(this.$refs.register_user).text('Register');

                                this.show_verification = true;

                                this.resetForm();
                            });
                        })
                        .catch(error=>{
                            if (error.response.data) {
                                if (typeof error.response.data.errors != 'undefined') {
                                    this.errors = error.response.data.errors;
                                } else {
                                    this.errors = error.response.data.message;
                                    this.registration_error_message = error.response.data.message;
                                }
                            }

                            console.log(error);
                            $(this.$refs.register_user).removeAttr('disabled');
                            $(this.$refs.register_user).text('Register')

                        });

                }

            },
            resetForm(){
                this.first_name = "";
                this.last_name = "";
                this.email = "";
                this.password = "";
                this.password_confirmation = "";
            }
        },
        computed : {
            ...mapGetters({
                base_url : 'baseUrl'
            })
        }
    }
</script>

