<template>

    <div class="bulletin-board mt-5 mb-5 container">

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1 class="text-dark">Bulletin Board</h1>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="social-media-links pull-right">
                    <ul style="list-style-type: none;">
                        <li style="display: inline;"><a href="https://www.facebook.com/OfficialSoundlaunch/" target="_blank"><img style="height: 30px; width: 30px;" :src="`${base_url}images/fb-icon.png`"></a></li>
                        <li style="display: inline;"><a href="https://twitter.com/Soundlaunch_Com" target="_blank"><img style="height: 30px; width: 30px;" :src="`${base_url}images/twitter.png`"></a></li>
                        <li style="display: inline;"><a href="https://www.linkedin.com/in/soundlaunch-soundlaunch-276a20167/" target="_blank"><img style="height: 30px; width: 30px;" :src="`${base_url}images/linkedin.png`"></a></li>
                        <li style="display: inline;"><a href="https://www.instagram.com/officialsoundlaunch/" target="_blank"><img style="height: 30px; width: 30px;" :src="`${base_url}images/instagram.png`"></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="mt-3 mb-3">
                    <div class="row">
                        <div class="col-lg-6">
                            <ul class="pagination">
                                <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
                                    <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.prev_page)"><i class="fa fa-backward"></i></a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link text-dark" href="#">Page <input @keyup="navigatePagination" v-model="current_page" :max="pagination.last_page" type="text" name="pagination_num" id="paginationNum" ref="paginationNum" /> of {{ pagination.last_page }}</a>
                                </li>
                                <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
                                    <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.next_page)"><i class="fa fa-forward"></i></a>
                                </li>
                            </ul>
                            <p class="text-danger">{{ pagination_error }}</p>
                        </div>
                        <div class="col-lg-6">
                            <div class="search-form form-group">
                                <span class="fa fa-search"></span>
                                <input type="text" v-model="search_val" @input="searchMusic" placeholder="Search" class="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="music in musics" v-bind:key="music.id" class="card mb-3" :data-music-id="music.id">
                    <div class="card-body">
                        <div class="music-photo">
                            <img @click="playMusic(music, 'bulletin-board')" class="rounded album-art" :src="`${ base_url }cover_photos/${ music.album_art }`">
                        </div>
                        <div class="music-description">
                            <h3 class="music-title">{{ music.title }}</h3>
                            <h5 class="music-artist">{{ music.artist }}</h5>
                        </div>
                    </div>
                    <div class="card-footer">
                        <p style="margin-bottom: 0"><span class="fa fa-comments"></span> Comments ( {{ music.comments.length }} ) </p>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent v-if="isLoggedin" :data-music-id="music.id">
                            <div class="form-group">
                                <textarea :ref="('c_input_'+music.id)" class="form-control" rows="3" name="comment" placeholder="Enter comment here..."></textarea>
                            </div>
                            <div class="pull-right">
                                <button v-if="edit" @click="cancelUpdate(edit_comment)" class="btn btn-default">Cancel</button>
                                <button class="btn btn-success" @click.prevent="postComment( music.id )">Post</button>
                            </div>
                        </form>
                        <div class="text-center" v-else><router-link :to="{ name: 'sign-in' }" class="nav-link" >Sign in to post a comment</router-link></div>
                        <div class="clearfix"></div>
                        <div class="text-center mb-3">
                            <span @click="toggleComments(music.id)" :ref="('c_title_'+music.id)"> Show Comments <i class="fa fa-arrow-down"></i></span>
                        </div>
                        <div class="hidden" :ref="('comment_'+ music.id)">
                            <ul style="list-style-type: none;" v-if="music.comments.length" v-for="comment in music.comments" v-bind:key="comment.id">
                                <li>
                                    <div v-if="user && comment.user.id == user.id" class="btn-group dropleft">
                                        <button style="background: none; border: none; color: #247673;" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="fa fa-ellipsis-v"></span>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a @click="updateComment(comment)" href="javascript:;" class="dropdown-item update">Update</a>
                                            <a @click="deleteComment(comment)" href="javascript:;" class="dropdown-item">Delete</a>
                                        </div>
                                    </div>
                                    <!-- <span class="comment-user">{{ comment.user.first_name + ' ' +comment.user.last_name  }}</span>  <span class="comment-timelapsed"><i class="fa fa-clock-o"></i> {{ comment.time_lapsed }}</span>
                                    <span style="display: block; font-size: 12px;">{{ comment.user.email }}</span> -->
                                    <span class="comment-user">{{ comment.user.first_name }}</span>  <span class="comment-timelapsed"><i class="fa fa-clock-o"></i> {{ comment.time_lapsed }}</span>
                                    <p class="comment-body">{{ comment.body }}</p>
                                    <span></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-3">
                    <ul class="pagination">
                        <li v-bind:class="[{disabled: !pagination.prev_page}]" class="page-item">
                            <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.prev_page)"><i class="fa fa-backward"></i></a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a>
                        </li>
                        <li v-bind:class="[{disabled: !pagination.next_page}]" class="page-item">
                            <a class="page-link" href="#" @click.prevent="fetchMusic(pagination.next_page)"><i class="fa fa-forward"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            </div>
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import FloatingPlayer from './../components/FloatingPlayer.vue';
import _ from 'lodash';

export default {
    data () {
        return {
            musics: [],
            opened_comments: [],
            pagination: {},
            pagination_error: '',
            comments: [],
            search_val: "",
            current_page: 1,
            edit: false,
            edit_comment: null,
            url_music_id: this.$route.query.musicId ? this.$route.query.musicId : null
        }
    },
    methods: {
        fetchMusic(page_url = "", search = "") {
            let vm = this;
            let url = this.base_url + "api/v1/bulletin-board";

            if (page_url) {
                if (search) {
                    url = page_url + "&search="+search;
                } else {
                    url = page_url;
                }
            } else {
                if (search) {
                    url = url + "?search="+search;
                }
            }

            axios.get(url)
                .then(response => {
                    this.musics = response.data.data;
                    this.makePagination( response.data.meta, response.data.links );

                    this.current_page = this.pagination.current_page;
                })
                .catch(error => alert(error));
        },
        searchMusic() {
            setTimeout(function() {
                this.fetchMusic('', this.search_val);
            }.bind(this), 2000);
        },
        navigatePagination() {
            this.pagination_error = '';
            let timeout = null;
            
            clearTimeout(timeout);

            timeout = setTimeout(function() {
                let toPage = this.current_page;
                let url = this.base_url + "api/v1/bulletin-board?page=" + toPage;

                if (toPage) {
                    this.fetchMusic(url);
                } else {
                    this.pagination_error = 'Pagination number empty.';
                }
            }.bind(this), 2000);
        },
        directToMusic(musicId) {
            let vm = this;
            let url = this.base_url + "api/v1/bulletin-board";

            if (musicId) {
                let musicIdPaginationUrl = url + '/get-music-pagination-page?musicId=' + musicId;

                axios.get(musicIdPaginationUrl).then(response => {
                    if (response.data.success) {
                        let id = response.data.data;
                        url = url + '?page=' + id;

                        axios.get(url).then(response => {
                            this.musics = response.data.data;
                            this.makePagination( response.data.meta, response.data.links );

                            setTimeout(function() {
                                $('html, body').animate({
                                    scrollTop: (($('.bulletin-board .card[data-music-id="' + musicId + '"]').offset()) ? $('.bulletin-board .card[data-music-id="' + musicId + '"]').offset().top : 0) - 250
                                }, 1000);

                                // $('.bulletin-board .card[data-music-id="' + musicId + '"] form textarea').focus();

                                if ($('.bulletin-board .card form[data-music-id="' + musicId + '"] textarea').length !== 0) {
                                    $('.bulletin-board .card form[data-music-id="' + musicId + '"] textarea').focus();
                                } else {
                                    $('.bulletin-board .card[data-music-id="' + musicId + '"] form textarea').focus();
                                }
                            }, 1000);

                            this.top_requests.filter(top_request => {
                                if (!musicId || top_request.music_id == musicId) {
                                    FloatingPlayer.methods.triggerMusicHit(this, top_request);
                                }
                            });
                        }).catch(error => console.log(error));
                    } else {
                        console.log('Error locating music ID pagination index');
                    }
                }).catch(error => console.log(error));
            } else {
                console.log('Bulletin Board Music ID not found!');
            }
        },
        playMusic(music, playTriggerComponent = null) {
            let selectedMusicId = (music.id) ? music.id : music.music_id;
            let currentPlayMusicId = (this.current_playing && this.current_playing.id) ? this.current_playing.id : (this.current_playing && this.current_playing.music_id) ? this.current_playing.music_id : 0;

            if (playTriggerComponent != null || playTriggerComponent != "") {
                this.$store.commit("setPlayTriggerComponent", playTriggerComponent);
            }

            if (this.current_playing != null && selectedMusicId == currentPlayMusicId) {
                FloatingPlayer.methods.audioReplay(this);
            }
            this.$store.commit('setPlaying', music);
            this.$store.commit('PlayFromList', { play: false, list: '', music: {} });

            // let user_agent = navigator.userAgent;
            // let hit = {
            //     "music_id" : music.id,
            //     "user_agent" : user_agent
            // };

            // axios.post(this.base_url+'api/v1/hit', hit )
            //         .then(response=>{
            //         })
            //         .catch(error=>console.log(error));
        },
        makePagination( meta, links ){

            let pagination = {
                current_page    :   meta.current_page,
                last_page       :   meta.last_page,
                next_page       :   links.next,
                prev_page       :   links.prev,
            }

            this.pagination = pagination;

        },
        toggleComments( music_id ){

            let comment_div = this.$refs['comment_'+music_id];

            if( $(comment_div).hasClass('hidden') ){
                $(this.$refs['c_title_'+music_id]).html('Hide Comments <i class="fa fa-arrow-up"></i>');
                $(comment_div).removeClass('hidden');
            }else{
                $(this.$refs['c_title_'+music_id]).html('Show Comments <i class="fa fa-arrow-down"></i>');
                $(comment_div).addClass('hidden');
            }
        },
        postComment( music_id ){

            if( this.user == null ){

                this.$swal({
                    type: 'warning',
                    title: "Login first to post a comment",
                }).then(result=>{
                    this.$router.push('sign-in');
                });

            }else{

                // let btn = e.target;

                // $(btn).attr('disabled','disabled');
                // $(btn).text('Posting...');

                let comment = $(this.$refs['c_input_'+music_id]).val();
                let url = this.base_url+"api/v1/post-comment";
                let formData = {
                    "music_id"  : music_id,
                    "user_id"   : this.user.id,
                    "body"      : comment,
                    "comment_id" : null,
                }
                let comment_div = this.$refs['comment_'+music_id];

                $(this.$refs['c_title_'+music_id]).html('Show Comments <i class="fa fa-arrow-down"></i>');
                $(comment_div).addClass('hidden');

                if( this.edit ){
                    url = this.base_url+"api/v1/update-comment";
                    formData.comment_id = this.edit_comment.id;
                    formData._method = "put";
                }

                axios.post(url, formData, this.axios_config)
                     .then(response=>{

                        let music = this.musics.filter( music => music.id == music_id );
                        if(this.edit){
                            music[0].comments.splice(music[0].comments.indexOf(this.edit_comment),1);
                        }
                        music[0].comments.unshift(response.data.data);
                        $(this.$refs['c_input_'+music_id]).val('');
                        this.edit=false;
                        this.edit_comment = null;


                        $(this.$refs['c_title_'+music_id]).html('Hide Comments <i class="fa fa-arrow-up"></i>');
                        $(comment_div).removeClass('hidden');

                        // $(btn).removeAttr('disabled');
                        // $(btn).text('Posting...');


                     })
                     .catch(error=>console.log(error));


            }


        },
        updateComment( comment ){

            $(this.$refs['c_input_'+comment.music_id]).val(comment.body);
            this.edit = true;
            this.edit_comment = comment;

        },
        cancelUpdate( comment ){

            $(this.$refs['c_input_'+comment.music_id]).val('');
            this.edit=false;
            this.edit_comment = null;
        },
        deleteComment( comment ){

            let data = {
                'user' : this.user.id,
                'id' : comment.id,
                '_method' : 'delete'
            };

            axios.delete(this.base_url+'api/v1/delete-comment', { "params" : data , "headers" : this.axios_config.headers} )
                .then(response=>{

                    let music = this.musics.filter( music => music.id == comment.music_id );
                    music[0].comments.splice(music[0].comments.indexOf(comment),1);

                })
                .catch(error=>console.log(error));

        },
        openPlayer() {
            this.$store.state.open_player = true;
        },
        closePlayer() {
            this.$store.state.open_player = false;
        },

    },
    created() {
        if (this.url_music_id) {
            this.directToMusic(this.url_music_id);
        } else {
            this.fetchMusic();
        }
    },
    computed : {
        ...mapGetters({
            base_url : 'baseUrl',
            isLoggedin : 'loginStatus',
            user : 'userValues',
            axios_config : 'axiosConfig',
            current_playing : 'currentPlaying',
            open_player: 'openPlayer',
            top_requests: 'topRequestsList',
        })
    },
    watch : {
        current_playing(newVal, oldVal){
            if (newVal !== null || newVal == oldVal) {
                this.selected = newVal;
            }

            if (this.$store.state.play_trigger_component != null || this.$store.state.play_trigger_component != "") {
                this.playMusic(newVal, this.$store.state.play_trigger_component);
            } else {
                this.playMusic(newVal);
            }
        },
        open_player(newVal, oldVal) {
            this.$store.commit('setOpenPlayer', newVal);
            // console.log('Home component open_player watcher newVal:', newVal);
            // console.log('Home component open_player watcher open_player state:', this.$store.state.open_player);
        },
        '$route.query.musicId': function(newVal, oldVal) {
            if (newVal !== null || newVal !== '') {
                this.directToMusic(newVal);
            }
        },
    },
}
</script>

<style scoped>
.comment-user{
    font-size: 15px;
    font-weight: 500;
}

.comment-timelapsed{
    margin-left: 10px;
    color: #247673;
}

.comment-body{
    margin-top: 10px;
    text-align: justify;
}

#paginationNum {
    width: 3.5vw;
    border: none;
    text-align: center;
    background: #fff;
    color: #343a40 !important;
}
</style>
