<template>
  <div class="container about-us mt-5">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <p style="font-size: 18px">
          Soundlaunch.com is a music website where the music you create can be heard by a worldwide audience. Amateurs and professional musicians, all genres can be heard on
          <router-link :to="{ name: 'home' }" class="about-links">Soundlaunch.com</router-link>.
          <br />
          <br />Checkout our
          <router-link :to="{ name: 'top-requests' }" class="about-links">Top Requested</router-link>.
          <br />
          <br />
          <router-link :to="{ name: 'index' }" class="about-links">Take A Listen!</router-link>
          <br />
          <br />Also check out the
          <router-link :to="{ name: 'bulletin-board' }" class="about-links">Bulletin Board</router-link>
          <br />where you can get information on music and other artist work.
          <br />
          <br />Don't forget to
          <router-link
            v-if="isLoggedIn && isAdmin"
            :to="{ name: 'music' }"
            class="about-links"
          >Launch Your Music!</router-link>
          <router-link
            v-else-if="isLoggedIn && !isAdmin"
            :to="{ name: 'my-account' }"
            class="about-links"
          >Launch Your Music!</router-link>
          <router-link v-else :to="{ name: 'sign-in' }" class="about-links">Launch Your Music!</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "loginStatus",
      isAdmin: "adminRights",
      base_url: "baseUrl"
    })
  }
};
</script>

<style>
a.about-links {
  text-decoration: none;
  color: #55e2de;
}
</style>
