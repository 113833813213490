<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
    <a class="navbar-brand">Soundlaunch.com</a>
    <button ref="togglerAdmin" class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav navbar-sidenav mr-3" id="exampleAccordion">
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
                <router-link :to="{ name: 'dashboard' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-dashboard"></i> Dashboard</router-link>
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Ads">
                <router-link :to="{ name: 'ads' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-video-camera"></i> Ads Management</router-link>
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Music">
                <router-link :to="{ name: 'music' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-music"></i> Music Management</router-link>
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Users">
                <router-link :to="{ name: 'users' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-users"></i> Users Management</router-link>
            </li>

        </ul>
        <ul class="navbar-nav sidenav-toggler">
            <li class="nav-item">
                <a class="nav-link text-center" id="sidenavToggler">
                    <i class="fa fa-fw fa-angle-left"></i>
                </a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Home">
                <router-link :to="{ name: 'index' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-home"></i> HOME</router-link>
            </li>
            <li class="nav-item" data-toggle="tooltip" data-placement="right" title="My Account">
                <router-link :to="{ name: 'my-account' }" @click.nav="toggleAdminHeader" class="nav-link"> <i class="fa fa-fw fa-user"></i> MY ACCOUNT</router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="logout" @click.nav="toggleAdminHeader"><i class="fa fa-sign-out"></i>SIGN OUT</a>
            </li>
        </ul>
    </div>
</nav>
</template>

<script>
export default {
    methods: {
        logout() {
            this.$store.dispatch('logout');
        },
        toggleAdminHeader() {
            $(this.$refs.togglerAdmin).click();
        }
    }
}
</script>

<style scoped>
.sidenav-toggler li {
    height: 56px;
}
</style>
