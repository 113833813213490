<template>
  <div class="top-requests mt-3 mb-5 container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="mt-5 search-filters text-center">
          <h3 class="text-dark" id="top-reference" v-observe-visibility="e => { showButton = !e }">Top Requests</h3>
          <ul class="list-inline time-list">
            <li @click.prevent="fetchTopRequests('All Time')" :class="{ 'active-selection' : time_search == 'All Time' }" class="list-inline-item"><a class="social-icon text-xs-center" target="_blank" href="javascript:void(0)">All Time</a></li>
            <li @click.prevent="fetchTopRequests('Year')" :class="{ 'active-selection' : time_search == 'Year' }" class="list-inline-item"><a style="text-decoration: none;" class="social-icon text-xs-center" href="javascript:void(0)">Year</a></li>
            <li @click.prevent="fetchTopRequests('Month')" :class="{ 'active-selection' : time_search == 'Month' }" class="list-inline-item"><a style="text-decoration: none;" class="social-icon text-xs-center" href="javascript:void(0)">Month</a></li>
            <li @click.prevent="fetchTopRequests('Week')" :class="{ 'active-selection' : time_search == 'Week' }" class="list-inline-item"><a style="text-decoration: none;" class="social-icon text-xs-center" href="javascript:void(0)">Week</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div v-if="top_requests" style="min-height: 377px; max-height: 100%;">
          <div class="text-center">
            <transition name="fade-in">
              <div v-if="show_top" class="top-music">
                <img @click="playMusic(top_requests[0])" class="rounded top-img" v-if="top_requests[0]" :src="`${ base_url }cover_photos/${top_requests[0].cover_photo}.${top_requests[0].img_file_ext}`">
                <h2 class="mt-3 text-dark" v-if="top_requests[0]">{{ top_requests[0].title }} by {{ top_requests[0].artist }}</h2>
                <h3 v-if="top_requests[0] && sorting === 'year'">with {{ top_requests[0].yearly_hit_count }} hits</h3>
                <h3 v-else-if="top_requests[0] && sorting === 'month'">with {{ top_requests[0].monthly_hit_count }} hits</h3>
                <h3 v-else-if="top_requests[0] && sorting === 'week'">with {{ top_requests[0].weekly_hit_count }} hits</h3>
                <h3 v-else>with {{ top_requests[0].hit_count }} hits</h3>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="mt-5 text-center">
          <h3 class="text-dark">Genres</h3>
          <ul class="list-inline genre-list">
            <li @click.prevent="firstPage" class="list-inline-item">
              <a href="javascript:void(0)">First</a>
            </li>
            <li @click.prevent="previousPage"  class="list-inline-item">
              <a href="javascript:void(0)"><<</a>
            </li>
            <li class="list-inline-item" @click.prevent="selectGenre('All Genre')" :class="{ 'active-selection' : selected_genre == 'All Genre' }"><a class="social-icon text-xs-center" href="javascript:void(0)">All Genres</a></li>
            <li v-for="genre in grouped_genres" @click.prevent="selectGenre(genre.description)" class="list-inline-item" :class="{ 'active-selection' : selected_genre == genre.description }"><a href="javascript:void(0)" class="social-icon text-xs-center">{{ genre.description }}</a></li>
            <li @click.prevent="nextPage"  class="list-inline-item">
              <a href="javascript:void(0)">>></a>
            </li>
            <li @click.prevent="lastPage"  class="list-inline-item">
              <a href="javascript:void(0)">Last</a>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      </div>
    </div>
    <div class="mt-3 pb-4" v-if="top_requests.length!=0">
      <transition-group name="list" tag="p">
        <div stagger="50"
        v-if="top_requests"
        v-for="( music, counter ) in top_requests.slice(1,top_requests.length)" v-bind:key="music.music_id" class="top-request mt-3 list-item" :class="{'active': current_playing !== null && current_playing.title == music.title}">
          <div class="rank"><span>{{ counter + 2 }}</span></div>
          <div class="photo">
            <img @click="playMusic(music)" :src="`${ base_url }cover_photos/${music.cover_photo}.${music.img_file_ext}`">
          </div>
          <div class="description">
            <span class="title truncated-text-line" style="width: 410px">{{ music.title }}</span>
            <span class="artist">{{ music.artist }}</span>
            <span class="hits mt-3 is-inline-block" v-if="sorting === 'year'">{{ music.yearly_hit_count }} <span style="font-size: .9rem; color: #bfbfbf">hits</span></span>
            <span class="hits mt-3 is-inline-block" v-else-if="sorting === 'month'">{{ music.monthly_hit_count }} <span style="font-size: .9rem; color: #bfbfbf">hits</span></span>
            <span class="hits mt-3 is-inline-block" v-else-if="sorting === 'week'">{{ music.weekly_hit_count }} <span style="font-size: .9rem; color: #bfbfbf">hits</span></span>
            <span class="hits mt-3 is-inline-block" v-else>{{ music.hit_count }} <span style="font-size: .9rem; color: #bfbfbf">hits</span></span>
            <div class="playlist-btns">
           
              <div v-if="music.like" class="action-btn mr-2" :data-id="music.music_id" @click="like(music.music_id, music.like)"><i data-test="yes" class="icon-liked-solid liked-active" :id="`myLike_${music.music_id}`"></i></div>
              <div v-else class="action-btn mr-2" :data-id="music.music_id" @click="like(music.music_id, music.like)"><i :id="`myLike_${music.music_id}`" data-test="no" class="icon-liked-solid"></i></div>
              
              
              
              <div v-if="music.favorite" class="action-btn mr-3" :data-id="music.music_id" @click="fave(music.music_id, music.favorite)"><i data-test="yes" class="icon-heart-solid favorite-active" :id="`myHeart_${music.music_id}`"></i></div>
              <div v-else class="action-btn mr-3" :data-id="music.music_id" @click="fave(music.music_id, music.favorite)"><i :id="`myHeart_${music.music_id}`" data-test="no" class="icon-heart-solid"></i></div>
              
              
              <div class="action-btn" @click="$emit('openPlaylistModal', music.music_id)"><i class="icon-add-to-playlist"></i></div>
            </div>
          </div>
        </div>
      </transition-group>
      <div class="view-more" v-if="showViewMore" @click="viewMore">View More</div><div class="view-more" v-else >Loading...</div>
    </div>
    <TopRequestLoadingSpinner :display="loading" v-else-if="loading == true && top_requests.length == 0" />
    <div class="mt-3 text-center fade" :class="{ 'show': loading == false && top_requests.length == 0 }">
      <h3>No matches found.</h3>
    </div>
    <div :class="['to-top', { 'show-top-button': showButton }]" v-scroll-to="'#top-reference'">
      <i class="fa fa-arrow-up" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
import TopRequestLoadingSpinner from '../components/TopRequestLoadingSpinner'

export default {
  components: {
    TopRequestLoadingSpinner
  },
  props: ['favorites', 'likes'],
  data() {
    return {
      showViewMore: true,
      sorting: 'all',
      show_top : false,
      grouped_genres : [],
      current_page : 1,
      number_per_page : 9,
      number_of_pages : 0,
      selected_genre : "All Genre",
      time_search : "All Time",
      loading: false,
      top_requests: [],
      showButton: false,
      songPaginator: {
        page: 0,
        totalPages: 0,
        time: '',
        genre: ''
      }
    }
  },
  computed : {
    ...mapGetters( {
      base_url : 'baseUrl',
      genres : 'genreList',
    }),
    ...mapState(['current_playing', 'random_music', 'open_player'])
  },
  methods : {
    addHitCount (music_id) {
      const index = this.top_requests.findIndex(top => top.music_id === music_id)
      if (this.time_search === "Year") {
        this.top_requests[index].yearly_hit_count += 1
      } else if (this.time_search === "Month") {
        this.top_requests[index].monthly_hit_count += 1
      } else if (this.time_search === "Week") {
        this.top_requests[index].weekly_hit_count += 1
      } else {
        this.top_requests[index].hit_count += 1
      }
    },
    async addHits (music_id) {
      const url = `${this.base_url}api/v1/hit`
      axios.post(url, { music_id })
      .then(() => {
        this.addHitCount(music_id)
      })
    },
    viewMore () {
        this.showViewMore = false
      this.songPaginator.page += 1
      if (this.songPaginator.page <= this,this.songPaginator.totalPages) {
        let url = this.base_url+'api/v1/top-requests2'
        axios.get(url, { params: this.songPaginator })
        .then(({ data }) => {
          if (data.songs) {
            const songs = Object.values(data.songs)
            this.$store.commit('addToTopRequestList', songs)
            const withFL = this.attachLikesFaves(songs)
            const local_index = this.top_requests.length - 1
            if (this.top_requests[local_index].music_id === withFL[0].music_id) {
              this.top_requests.pop()
              console.log(this.top_requests, 'fixed')
            }
            this.top_requests = this.top_requests.concat(withFL)
            this.showViewMore = true
            this.showViewMore = !(data.end_page)
            this.removeDuplicates()
            // console.info(this.top_requests)
          }
        })
      }
    },
    removeDuplicates () {
        let unique = []
        const myunique = this.top_requests.reduce((acc, item) => acc.set(item.id, item), new Map());
        this.top_requests.forEach(function(item){
            if(!unique.includes(item)) {
                unique.push(item)
            }
        })
        console.info('Unique Count '+unique.length)
        console.info('ReyUnique Count '+myunique.length)
        this.top_requests = unique
    },
   like(id, src) {

      if (document.getElementById("myLike_"+id).className == "icon-liked-solid liked-active") {
        document.getElementById("myLike_"+id).className = "icon-liked-solid";
      } else {
         document.getElementById("myLike_"+id).className = "icon-liked-solid liked-active";
      }
      this.$emit('like', id)
    },
    fave(id, src) {

      if (document.getElementById("myHeart_"+id).className == "icon-heart-solid favorite-active") {
        document.getElementById("myHeart_"+id).className = "icon-heart-solid";
      } else {
         document.getElementById("myHeart_"+id).className = "icon-heart-solid favorite-active";
      }

          this.$emit('favorite', id)
    },
    attachLikesFaves(d) {
      d.forEach(item => {
        item.like = this.likes.some(id => id === item.music_id)
      })
      d.forEach(item => {
        item.favorite = this.favorites.some(id => id === item.music_id)
      })
      return d
    },
    fetchTopRequests( time = "" ){
      this.loading = true

      let url = this.base_url+'api/v1/top-requests2';
      const params = {
        genre: this.selected_genre,
        time: (time !== "") ? `${time}` : null,
        page: 1
      }

      this.time_search = (time !== "") ? `${time}` : null;
      axios.get(url, { params })
        .then(response => {
          this.loading = false
          this.songPaginator.genre = this.selected_genre
          this.songPaginator.time = time
          this.songPaginator.totalPages = Number(response.data.pages)
          this.songPaginator.page = 1

          if (response.data.songs.length < 1) {
            console.log('1')
            this.top_requests = []
            this.show_top = false
            this.showViewMore = false
          } else {
            console.log('2')
            this.$store.commit('setTopRequestsList', response.data.songs)
            const data = this.$store.state.top_requests_list
            this.top_requests = this.attachLikesFaves(data)
            this.sorting = response.data.sorting
            this.show_top = false
            setTimeout(function(){
                if (this.top_requests.length == 0) {
                  this.show_top = false;
                } else {
                  this.show_top = true;
                }
            }.bind(this), 500);
          }
        })
        .catch(error=>console.log(error));
    },
    playMusic( music ){
      this.addHits(music.music_id)
      let selectedMusicId = (music.id) ? music.id : music.music_id;
      let currentPlayMusicId = (this.current_playing && this.current_playing.id) ? this.current_playing.id : (this.current_playing && this.current_playing.music_id) ? this.current_playing.music_id : 0;

      // if (this.current_playing != null && selectedMusicId == currentPlayMusicId) {
      //   FloatingPlayer.methods.audioReplay(this);
      // }
      this.$store.commit('setPlaying', music);
      this.$store.commit('PlayFromList', { play: false, list: '', music: {} });
    },
    fetchGenres(){
      this.loading = true
      axios.get(this.base_url+'api/v1/genre')
        .then(response=>{
          this.loading = false
          this.$store.commit('setGenres', response.data.data);
          this.number_of_pages = this.getNumberOfPages();
          this.loadList();

        })
        .catch(error=>console.log(error));
    },
    loadList() {
        let begin = ((this.current_page - 1) * this.number_per_page);
        let end = begin + this.number_per_page;

        this.grouped_genres = this.genres.slice(begin, end);

    },
    nextPage() {
        if(this.current_page < this.number_of_pages)
          this.current_page++;

        this.loadList();
    },
    previousPage() {
      if( this.current_page > 1 )
        this.current_page--;

      this.loadList();
    },
    firstPage() {
      this.current_page = 1;
      this.loadList();
    },
    lastPage() {
      this.current_page = this.number_of_pages;
      this.loadList();
    },
    selectGenre( genre ) {
      this.selected_genre = genre
      this.fetchTopRequests()

    },
    getNumberOfPages(){
      return Math.ceil(this.genres.length/ this.number_per_page);
    }
  },
  created() {
    this.fetchTopRequests()
    this.fetchGenres()
  },
  watch : {
    time_search(newValue, oldValue){
      $('.time-list li').each(function(index){
        $(this).find('a').removeClass("active-time-selection");
      });
      $(".time-list li:contains('"+newValue+"')").find('a').addClass('active-time-selection');
    },
    top_requests_list(newVal, oldVal) {
      this.top_requests = newVal;
    }
  }
}

</script>

<style>
.view-more {
  text-align: center;
  cursor: pointer;
  padding: 1rem;
  color: rgb(0, 138, 223);
}
.to-top {
  position: fixed;
  opacity: 0;
  bottom: -6rem;
  right: 1.5rem;
  height: 64px;
  width: 64px;
  background-color: rgb(0, 138, 223);
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 2rem;
  padding-top: 6px;
  transition: .8s linear;
  cursor: pointer;
  z-index: 300;
}

.to-top.show-top-button {
  bottom: 3rem;
  opacity: 1;
}
.liked-active {
    color: rgb(0, 138, 223) !important;
}

.favorite-active {
    color: rgb(199, 0, 0) !important;
}

.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.fade-in-enter-active {
    animation: fade-in .5s;
}

.fade-in-leave-active {
    animation: fade-in .5s reverse;
    /* animation: none; */
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.time-list li:not(:last-child),
.genre-list li:not(:last-child) {
    margin-right: 0px !important;
}

.time-list li + li:before,
.genre-list li + li:before {
    content: " | ";
    padding: 0 10px;
    color: black !important;
    font-weight: 100;
}

.active-selection,
.active-selection * {
    font-weight: 600;
    color: #2855b9 !important;
}

.active-time-selection{
    font-weight: 600;
}

.time-list li a,
.genre-list li a {
    text-decoration: none !important;
    color: black;
}

.genre-list li{
    cursor: pointer;
}

.list-enter-active, .list-leave-active {
  transition: all .5s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.top-img{
    width: 250px;
    height: 250px;
}

.top-request{
  background-color: #1b1b1b;
}

.top-request .rank{
    display: table-cell;
    width: 100px;
    vertical-align: middle;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #247673;
}

.top-request .photo{
    height: 100%;
    display: table-cell;
}

@media (max-width: 767px) {
  .top-request .photo {
    display: inline-block;
    margin-bottom: 12px;
    margin-right: 10px;
  }
  .top-request .rank {
    display: inline-block;
    width: 52px;
  }
  .top-request .description {
    padding-left: 0 !important;
    display: inline-block !important;
    width: calc(100% - 240px);
  }
  .top-request .description .truncated-text-line {
    width: 65% !important;
    min-width: 350px;
  }
}

.top-request .photo img{
    height: 115px;
    width: 115px;
}


.top-request .description{
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
    color: #fff;
}

.top-request .description .title{
    display: block;
    font-size: 1.25rem;
    font-weight: normal !important;
    margin-bottom: .3em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white !important;
}

.top-request .description .artist{
    font-size: .95rem;
    display: block;
    color: #8c8c8c;
}

.top-request.list-item.active {
    background-color: #60cac6 !important;
}

.playlist-btns {
  font-size: .95rem;
  margin-top: .25em;
}
</style>
