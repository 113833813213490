<template>
  <div>
    <form class="form-signin">
      <div class="text-center mb-4">
        <h1 class="h3 mb-3 text-white">Sign In</h1>
      </div>

      <div class="form-group">
        <input v-model="email" type="email" name="email" id="email" class="form-control" placeholder="Email address" required="" autofocus="" autocomplete="off" @input="onEmailEvent()">
      </div>

      <div class="form-group">
        <input v-model="password" type="password" name="password" id="inputPassword" class="form-control" placeholder="Password" required="" autocomplete="off" @input="onPasswordEvent()">
      </div>

      <p v-if="login_error" class="text-danger" style="font-weight: 700">{{ login_error_message }}</p>
      <button class="btn btn-lg btn-primary btn-block" type="submit" @click="handleSubmit">Sign in</button>
      <br>
      <p class="text-center">
        <router-link :to="{ name: 'register' }" class="nav-link" >Not yet a member?</router-link>
        <router-link :to="{ name: 'forgot-password' }" class="nav-link" >Forgot Password?</router-link>
      </p>
      <p class="mt-5 mb-3 text-white text-center"> All Rights Reserved © 2019 Soundlaunch</p>
    </form>
  </div>
</template>

<script>
export default{
  data() {
    return {
      email   : "",
      password: "",
      login_error_message : "",
      login_error : false,
      base_url : ""
    }
  },
  methods : {
    handleSubmit(e) {
      e.preventDefault();

      if (this.password.length > 0) {
        let email = this.email
        let password = this.password

        let btn = e.target;
        $(btn).attr('disabled', 'disabled');
        $(btn).text("Signing in");

        axios.post(this.base_url+'api/v1/login', {email, password})
          .then(response => {
            switch (response.data.code) {
              case "200":
                let user = response.data.user;
                let is_admin = user.is_admin;

                localStorage.setItem('soundlaunch.user', JSON.stringify(user))
                localStorage.setItem('soundlaunch.token', response.data.token)

                let token = localStorage.getItem('soundlaunch.token');
                let config = {
                  'Content-Type' : 'application/json',
                  'Authorization' : 'Bearer ' + token
                };

                this.$store.commit('loginUser');
                this.$store.commit('setAdminRights', is_admin==="Y" ? true : false);
                this.$store.commit('saveUser', user);
                this.$store.commit('setAxiosConfig', config);

                $(btn).removeAttr('disabled');
                $(btn).val("Sign in");
                this.email = '';
                this.password = '';
                this.login_error = false;

                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl)
                } else {
                  this.$router.push((is_admin == "Y" ? '/admin/dashboard' : '/my-account'))
                }
                this.$emit('initPlaylist')
              break;
              case "401":
                this.login_error = true;
                this.login_error_message = "Login failed! Incorrect email/password.";
                $(btn).removeAttr('disabled');
                $(btn).text("Sign in");
              break;
              case "406":
                this.login_error = true;
                this.login_error_message = "Login failed! Please verify your email.";
                $(btn).removeAttr('disabled');
                $(btn).text("Sign in");
              break;
            }
          }).
          catch(error => {
            console.log(error);
            $(btn).removeAttr('disabled');
            $(btn).text("Sign in");
          });
        }
      },
      redirect() {
        if (this.$store.getters.loginStatus) {
          let user = this.$store.getters.userValues;
          console.log(user);

          this.email = null;
          this.password = null;
          this.login_error = false;
          this.login_error_message = null;

          this.$router.push((user.is_admin == "Y" ? '/admin/dashboard' : '/my-account'))
        }
      },
      onEmailEvent() {
        this.login_error = false;
        this.login_error_message = null;
      },
      onPasswordEvent() {
        this.login_error = false;
        this.login_error_message = null;
      }
  },
  created() {
    this.base_url = this.$store.state.base_url;
    this.redirect();
  },
  activated: function () {
    this.$nextTick(function () {
      this.email = null;
      this.password = null;
      this.login_error = false;
      this.login_error_message = null;
    })
  }
}
</script>
