<template id="bs-modal">
    <!-- MODAL -->
    <div class="modal fade" id="contactUsModal" tabindex="-1" role="dialog" aria-labelledby="contactUsModalLabel">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form class="form-vertical" @submit.prevent="submitEmail()">
                    <div class="modal-header">
                        <h4 class="modal-title" id="contactUsModalLabel">{{ title = contactUsTitle }}</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <input type="hidden" v-model="to = contactUsEmail" name="to">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="name">Your Name</label>
                                <input type="text" class="form-control" name="name" required v-model="name"/>
                            </div>
                            <div class="col-md-6">
                                <label>Your Email</label>
                                <input type="email" class="form-control" name="from" required v-model="from" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label class="">Subject</label>
                                <input type="text" class="form-control" name="subject" required v-model="subject"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label for="message">Message:</label>
                                <textarea class="form-control" rows="5" id="message" name="message" v-model="msg"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" v-if="!emailSubmitting">Send</button>
                        <button type="button" class="btn btn-primary disabled" disabled v-else>
                            <span><i class="fa fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: [
        'contactUsEmail',
        'contactUsTitle',
    ],
    data() {
        return {
            title: '',
            to: '',
            name: '',
            from: '',
            subject: '',
            msg: '',
            emailSubmitting: false
        }
    },
    methods: {
        submitEmail() {
            this.emailSubmitting = true

            let formData = new FormData()

            formData.append('to', this.to)
            formData.append('from', this.from)
            formData.append('name', this.name)
            formData.append('subject', this.subject)
            formData.append('msg', this.msg)

            axios.post('/api/v1/contact-us', formData, this.axios_config)
            .then(response => {
                this.emailSubmitting = false

                $('#contactUsModal').modal('hide')
                this.clearForm()

                this.$swal({
                    type: 'success',
                    title: "E-mail successfully submitted!",
                    showConfirmButton: false,
                    timer: 1500
                })

                console.log(response)
            })
            .catch(error => {
                this.emailSubmitting = false

                this.$swal({
                    'type' : 'error',
                    'title': 'Opps!',
                    'text' : 'Error submitting e-mail.'
                })

                console.log(error)
            })
        },
        clearForm() {
            this.title = ''
            this.to = ''
            this.from = ''
            this.name = ''
            this.subject = ''
            this.msg = ''
        }
    },
    computed : {
        ...mapGetters({
            base_url : 'baseUrl',
            axios_config : 'axiosConfig',
            user : 'userValues'
        })
    },
    mounted() {
        $('#contactUsModal').on("hidden.bs.modal", this.clearForm)
    }
}
</script>

<style scoped>

</style>
