<template>
  <b-modal :active="showModal" @close="$emit('close-subscribe-modal', {orderID: orderID, paid: paid})" :width="475">
    <div v-if="completed">
      <div class="px-4 py-4 has-text-centered">
        <span class="is-size-3 has-text-success mb-3">
          <i class="fa fa-check-circle" />
        </span>
        <br />
        <span class="is-size-4 has-text-weight-semibold">You can now add UNLIMITED SONGS and PLAYLISTS!</span><br />
        <span>Order ID: {{orderID}}</span><br />
        <span>Payment Received.</span><br />
      </div>
    </div>
    <div v-else>
      <div class="px-2 py-3 has-text-centered">
        <p class="is-size-5">
          You have reached your limit.
        </p>
      </div>
      <div class="px-4 pb-3 mt-4" style="padding-right: 2rem">
          <button class="button is-small is-fullwidth mt-4" @click="$emit('close-subscribe-modal')">Close</button>
      </div>

    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['showModal', 'base_url'],
  data () {
    return {
      subsPrice: 5,
      currency: '$',
      completed: false,
      paid: 0,
      orderID: ''
    }
  },
  methods: {
    onApprove (details) {
      this.orderID = details.id
      this.completed = true
      this.paid = Number(details.purchase_units[0].amount.value)
      this.savePurchase()
    },
    async savePurchase () {
    },
    pay () {
      const instance = this
      const button = paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '5.00'
              }
            }]
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(details => {
            instance.onApprove(details)
          })
        },
        style: {
          color: 'blue',
          label: 'pay'
        }
      })
      if (button.isEligible()) {
        button.render(this.$refs.pay)
      }
    }    
  }
}
</script>
