<template>

    <div class="mb-3">
        <h1>Dashboard</h1>
        <div class="row mt-5">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: table-cell; vertical-align: middle; font-size: 50px">
                            <span><i class="fa fa-users"></i></span>
                        </div>
                        <div style="display: table-cell; padding-left: 10px;">
                            <div class="figure-count">
                                <span>{{ user_count }}</span>
                            </div>
                            <div class="">
                                No. of users
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: table-cell; vertical-align: middle; font-size: 50px">
                            <span><i class="fa fa-music"></i></span>
                        </div>
                        <div style="display: table-cell; padding-left: 10px;">
                            <div class="figure-count">
                                <span>{{ music_count }}</span>
                            </div>
                            <div class="">
                                No. of music
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: table-cell; vertical-align: middle; font-size: 50px">
                            <span><i class="fa fa-video-camera"></i></span>
                        </div>
                        <div style="display: table-cell; padding-left: 10px;">
                            <div class="figure-count">
                                <span>{{ ad_count }}</span>
                            </div>
                            <div class="">
                                No. of advertisements
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { mapGetters } from 'vuex';

export default {

    data() {
        return {
            user_count : 0,
            music_count : 0,
            ad_count : 0
        }
    },
    methods :{
        fetchDashboardDetails(){

            axios.get(this.base_url+'api/v1/dashboard', this.axios_config)
                 .then(response=>{

                    this.user_count = response.data.user_count;
                    this.music_count = response.data.music_count;
                    this.ad_count = response.data.ad_count;

                 })
                 .catch(error=>console.log(error));

        },
        redirect(){

            if( this.user.is_admin != "Y" ){
                this.$router.push('/');
            }

        },
    },
    created(){
        this.fetchDashboardDetails();
        this.redirect();
    },
    computed : {
        ...mapGetters({
            base_url : 'baseUrl',
            axios_config : 'axiosConfig',
            user : 'userValues'
        })
    }

}

</script>

<style>

.figure-count{

    font-size: 30px;
    font-weight: 700;

}

</style>
