<template>
    <div class="row">
        <div class="col-sm-2 col-xs-12"></div>
        <div class="col-sm-8 col-xs-12">
            <h1 class="text-center">
                SoundLaunch.com Copyright Infringement Procedure
            </h1>
            <br>
            <br>
            <br>
            <p class="text-justify">
                If unauthorized copyrighted material was submitted without the owner/s permission you can request a removal of copyrighted material from the Soundlaunch.com website by sending an email to <a href="mailto:copyright_infringement@soundlaunch.com" data-toggle="modal" data-target="#contactUsModal" @click.prevent="contactUsEmail = 'copyright_infringement@soundlaunch.com';  contactUsTitle = 'Copyright Infringement Concerns'">copyright_infringement@soundlaunch.com</a>. This action can only be done by the original owner/s or authorized personnel.
                <br>
                <br>
                Any false copyright infringement claims are in direct violation of Soundlaunch.com policy and could lead to legal ramifications, in addition to your account being suspended or closed.
                <br>
                <br>
                <b>At Soundlaunch.com we strive to protect copyrighted material.</b>
            </p>
        </div>
        <div class="col-sm-2 col-xs-12"></div>
        <ContactUsModal :contactUsEmail="contactUsEmail" :contactUsTitle="contactUsTitle"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactUsModal from '../components/ContactUsModal'

export default {
    components: {
        ContactUsModal
    },
    data() {
        return {
            latest : [],
            top: [],
            comments : [],
            contactUsEmail: '',
            contactUsTitle: ''
        }
    },
    computed : {
        ...mapGetters({
            isLoggedIn : 'loginStatus',
            isAdmin : 'adminRights',
            base_url : 'baseUrl'
        })
    }
}
</script>
