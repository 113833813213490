<template>
<div class="admin-div">

    <Header></Header>
    <AdminHeader></AdminHeader>
    <div class="content-wrapper">
        <div class="container-fluid">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
    </div>
</div>
</template>

<script>
import AdminHeader from "../components/AdminHeader.vue";
import Header from "../components/Header.vue";

export default {
    data() {
        return {
            is_loading: false
        }
    },
    components: {
        AdminHeader,
        Header
    },
    watch: {
        isLoading(newValue, oldValue) {
            this.is_loading = newValue;
        }
    },
    created() {
        this.$store.dispatch('reAuth');
    }

}
</script>
