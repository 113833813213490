<template>
  <div style="position: fixed; z-index: 301;">
  <subscribe-modal :base_url="base_url" :showModal="subscribe" @close-subscribe-modal="closeSubscribe" ref="subs" />
  <div id="showPlaylist-button" v-on:click="showPlaylist = !showPlaylist" v-show="!showPlaylist">
    <p class="icon-open-playlist">
      <span class="playlist-icon-playing-indicator" v-if="playing.fromList">
        Playing from:<br/><span class="running-playlist">{{ playing.list }}</span>
      </span>
    </p>
  </div>
  <div v-if="showPlaylist" class="playlist-container d-flex" v-click-outside="onClickOutside">
    <div class="list-container">

      <b-menu>
        <b-menu-list>
          <b-menu-item label="Random Music" :active.sync="isActive" @click="setTableList(0)" />
          <b-menu-item expanded :label="`My Playlist [${playlists.length}]`">

      <draggable
        :list="playlists"
        :disabled="!enabled"
        class="list-group"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="dragEnd"
      >
        <div
          v-for="element in playlists"
          :key="element.name"

        >

               <div class="is-pulled-left">
                  <div class="mr-2" style="display: inline-block" @click="renamePlaylist(element)">
                    <i data-id="22222" class="fa fa-edit" />
                  </div>
                  <div class="mr-2" style="display: inline-block" @click="deletePlaylist(element.id)">
                    <i class="fa fa-trash" />
                  </div>
                </div>

        <div v-if="tableID == element.id" class="highlight myPlaylistTitle pulled-left mr-4" @click="setTableList(element.id)" >{{ element.name }}
        </div>
         <div v-else class="myPlaylistTitle pulled-left mr-4" @click="setTableList(element.id)" >{{ element.name }}
        </div>


        </div>
      </draggable>


    <div class="col-3" :value="list" title="List" />

          </b-menu-item>
          <b-menu-item label="Songs You Like" @click="setTableList(-1)" />
          <b-menu-item label="Favorite Songs" @click="setTableList(-2)" />
        </b-menu-list>
      </b-menu>
    </div>
    <div class="px-2 py-2 mobile-width" style="overflow-y: auto;">
      <div class="list-container-mbl">
        <div class="container-mbl-option">
          <label style="font-size: 18px; font-weight: 600; margin: 0px;" for="">Select Playlist</label>
        </div>
        <b-menu>


        <b-menu-list>
          <b-menu-item label="Random Music 1" :active.sync="isActive" @click="setTableList(0)" />
          <b-menu-item expanded :label="`My Playlist[${playlists.length}]`">


            <draggable
        :list="playlists"
        :disabled="!enabled"
        class="list-group"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="dragEnd"
      >
        <div
          v-for="element in playlists"
          :key="element.id"
        >

               <div class="is-pulled-left">
                  <div class="mr-2" style="display: inline-block" @touchstart="renamePlaylist(element)">
                    <i data-id="111111" class="fa fa-edit" />
                  </div>
                  <div class="mr-2" style="display: inline-block" @touchstart="deletePlaylist(element.id)">
                    <i class="fa fa-trash" />
                  </div>
                </div>
        <div v-if="tableID == element.id" class="highlight myPlaylistTitle pulled-left mr-4" @touchstart="setTableList(element.id)" >{{ element.name }}
        </div>
         <div v-else class="myPlaylistTitle pulled-left mr-4" @touchstart="setTableList(element.id)" >{{ element.name }}
        </div>


        </div>
      </draggable>
          </b-menu-item>
          <b-menu-item label="Songs You Like" @click="setTableList(-1)" />
          <b-menu-item label="Favorite Songs" @click="setTableList(-2)" />
        </b-menu-list>
      </b-menu>
      </div>
          <br/>
          <table-pager :pager="pagerTotalSongs" :activePage="activePage" :showControls="showControls" @get-page="getPage"  />
        <thead>
          <tr>
            <th class="text-center number-con">No.</th>
            <th class="title-con">Title</th>
            <th class="artist-con">Artist</th>
            <th class="text-center action-con">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="index">
            <td class="text-center number-con">{{ ( index) + 1 }}</td>
            <td :class="['title-con', 'clickable', { 'playing': ($store.state.current_playing && row.id === $store.state.current_playing.id) }]" @click="play(row)">{{ row.title }}</td>
            <td class="artist-con">{{ row.artist }}</td>
            <td class="text-center action-con">
              <div class="action-btn" @click="$emit('openPlaylistModal', row.id)" v-if="!tableID||tableID === -1||tableID === -2"><i class="icon-add-to-playlist"></i></div>
              <div class="action-btn" @click="setLike(row.id)"><i :class="['icon-liked-solid', { 'liked-active': row.like }]"></i></div>
              <div class="action-btn" @click="setFavorites(row.id)"><i :class="['icon-heart-solid', { 'favorite-active': row.favorite }]"></i></div>
              <div class="action-btn" @click="removeSongFromPlaylist(row.id, row.title)" v-if="tableID > 0"><i class="fa fa-trash"></i></div>
            </td>
          </tr>
        </tbody>
      </table>

<table-pager :pager="pagerTotalSongs" :activePage="activePage" :showControls="showControls" @get-page="getPage"  />
    </div>
    <b-tooltip label="Hide Playlist" type="is-dark" position="is-left">
      <button v-on:click="showPlaylist = !showPlaylist" id="close-playlist-btn" class="icon-arrow-left"></button>
    </b-tooltip>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SubscribeModal from '../components/SubscribeModal.vue'
import TablePager from '../components/Pager.vue'
import vClickOutside from 'v-click-outside'
import draggable from 'vuedraggable'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { Table, Input } from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Table)
Vue.use(Input)
Vue.use(Buefy)


export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['likes', 'favoriteList'],
  data: () => ({
      selected: [],
       isPaginated: true,
                isPaginationSimple: false,
                isPaginationRounded: false,
                paginationPosition: 'bottom',
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small',
                currentPage: 1,
                data: [
                    { 'id': 1, 'first_name': 'Jesse', 'last_name': 'Simmons', 'date': '2016-10-15 13:43:27', 'gender': 'Male' },
                    { 'id': 2, 'first_name': 'John', 'last_name': 'Jacobs', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
                    { 'id': 3, 'first_name': 'Tina', 'last_name': 'Gilbert', 'date': '2016-04-26 06:26:28', 'gender': 'Female' },
                    { 'id': 4, 'first_name': 'Clarence', 'last_name': 'Flores', 'date': '2016-04-10 10:28:46', 'gender': 'Male' },
                    { 'id': 5, 'first_name': 'Anne', 'last_name': 'Lee', 'date': '2016-12-06 14:38:38', 'gender': 'Female' }
                ],
                columns: [
                    {
                        field: 'title',
                        label: 'Title',
                        searchable: true
                    },
                    {
                        field: 'artist',
                        label: 'Artist',
                        searchable: true
                    },
                    {
                        field: 'action',
                        label: 'Action',
                        searchable: false
                    }
                ],
    enabled: true,
      list: [
        { name: "John1111", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 }
      ],
      dragging: false,
    subscribe: false,
    // isSubscribed: false,
    isSubscribed: true,
    isActive: true,
    newplaylist: 'show',
    showPlaylist: false,
    isCardModalActive: false,
    items: [],
    tableID: 0,
    playlistOnView: 0,
    base_url: '',
    userID: 0,
    loading: false,
    musics: [],
    selectedMusic: 0,
    liked: [],
    favorites: [],
    playlistSongs: [],
    activePage: 1,
    drag: false,
    people: [],
    myplaylist: [],
    options: {
                handle: '.handle',
                filter: '.disabled'
            },
          myArray: [
            {
              id: 0, name: 'Testing'
            },
            {
              id: 1, name: 'Testing1'
            }
          ],
          issorting: true
  }),

  computed: {
    ...mapGetters({
      isLoggedIn: "loginStatus",
      isAdmin: "adminRights",
      base_url: "baseUrl"
    }),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    playlists () {
      if(this.isAdmin){
        return this.$store.state.playlists
      }else{
        if (!this.$store.state.isSubscribed && this.$store.state.playlists.length > 5) {
          return this.$store.state.playlists.slice(0, 5)
        } else {
          return this.$store.state.playlists
        }
      }
    },
    pagerTotalSongs () {
      if (this.tableID === -1) {
        return this.liked.length
      } else if (this.tableID === -2) {
        return this.favorites.length
      } else {
        return this.playlistSongs.length
      }
    },
    perPage () {
      return this.$store.state.perPage
    },
    playing () {
      return this.$store.state.play_from_list
    },
    showControls () {
      console.log("showControls")
      if (this.tableID === 0) {
        return false
      } else if (this.tableID === -1)
      {
        return (this.playing.list === 'likes') ? true : false
      } else if (this.tableID === -2) {
        return (this.playing.list === 'favorites') ? true : false
      } else {
        return this.playlists
      }
    }
  },

  components: {
    SubscribeModal, TablePager, draggable
  },

  methods: {
    dragEnd (e) {
      this.savePlaylistOrder()
    },
    dragStart () {
      console.log("Drag Start")
    },
    add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
    },
    replace: function() {
      this.list = [{ name: "Edgard", id: id++ }];
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    playlistOrder () {
       console.log("playlistOrder")
      console.info(this.playlist)
    },
    play (music = null, play = true) {
      if (music === null) {
        this.$store.commit('PlayFromList', { list: '', play: false, music: -1 })
      } else {
        if (this.playlistOnView > 0) {
          console.log("11111")
          const list = this.playlists.filter(playlist => playlist.id === this.playlistOnView)[0].name
          this.$store.commit('PlayFromList', { list, play: play, music: music.id })
        } else if (this.playlistOnView === -1) {
         console.log("")
       this.$store.commit('PlayFromList', { list: '', play: false, music: -1 })
        } else if (this.playlistOnView === -2) {
          const list = 'favorites'
           this.$store.commit('PlayFromList', { list: '', play: false, music: -1 })
         this.$store.commit('PlayFromList', { list, play: play, music: music.id })
        }
        this.$store.commit('setPlaying', music)
      }
    },
    getPage (page) {
      if (this.tableID === -1) {
        this.items = this.setPage(this.liked, page, this.perPage)
      } else if (this.tableID === -2) {
        this.items = this.setPage(this.favorites, page, this.perPage)
      } else {
        this.items = this.setPage(this.playlistSongs, page, this.perPage)
      }
    },
    setPage (src, page, count) {
      this.activePage = page
      if (count > src.length) {
        return src
      } else {
        let end = 0
        const start = count * (page - 1)
        const possibleEnd = start + count
        if (src.length > possibleEnd) {
          end = possibleEnd
        } else {
          end = src.length
        }

        console.log(page)
        return src.slice(start, end)
      }
    },
    setLike (id) {
      this.$emit('like', id)
      const index = this.items.findIndex(item => item.id === id)
      this.items[index].like = !this.items[index].like
    },
    updateLikes (data) {
      this.liked = this.attachLikesFaves(data)
      if (this.tableID === -1) {
        this.liked = this.liked.filter(like => like.like)
        this.items = this.liked
      }
      this.$forceUpdate()
    },
    setFavorites (id) {
      this.$emit('favorite', id)
      const index = this.items.findIndex(item => item.id === id)
      this.items[index].favorite = !this.items[index].favorite
    },
    updateFavorites (data) {
      this.favorites = this.attachLikesFaves(data)
      if (this.tableID === -2) {
        this.favorites = this.favorites.filter(f => f.favorite)
        this.items = this.favorites
      }
      this.$forceUpdate()
    },
    onClickOutside (e) {
      if (this.showPlaylist) {
        this.showPlaylist = false
      }
    },
    attachLikesFaves(d) {
      d.forEach(item => {
        item.like = this.likes.some(id => id === item.id)
      })
      d.forEach(item => {
        item.favorite = this.favoriteList.some(id => id === item.id)
      })
      return d
    },
    closeSubscribe({ orderID, paid }) {
      this.subscribe = false
      if (orderID !== '' || orderID !== null) {
        this.save({
          orderID,
          paid
        })
      }
    },
    async save({ orderID, paid }) {
      await axios.post(`${this.base_url}/purchases`, {
        'user_id': this.userID,
        'order_id': orderID,
        'paid_amount': paid,
        'purchase_name': 'Unlimited Playlist and Songs'
      })
      await this.fetchUserPurchaseState()
    },
    async savePlaylistOrder() {
      await axios.post(`${this.base_url}/saveplaylistorder`, {
        'playlists': this.playlists
      })
    },
    async setTableList(id) {
      this.tableID = id
      this.activePage = 1
      if (id) {
        if (id === -1) {
          this.playlistOnView = id
          this.getPage(1)
        } else if (id === -2) {
          this.playlistOnView = id
          this.getPage(1)
        } else {
          await this.setPlaylistOnTable(id)
          this.getPage(1)
        }
      } else {
        this.items = this.musics
        this.playlistOnView = id
      }
    },
    async setPlaylistOnTable(playlist_id) {
      this.playlistOnView = playlist_id
      this.playlistSongs = await this.getPlaylistMusic(playlist_id)
    },
    async getPlaylistMusic(playlist_id) {
      let list = []
      await axios.get(`${this.base_url}/playlist/${playlist_id}`)
        .then(({ data }) => {
          data.data.forEach(song => {
            song.album_art = `${song.cover_photo}.${song.img_file_ext}`
          })
          list = this.attachLikesFaves(data.data)
        })
      if(this.isAdmin){
          return list
      }else{
        if (!this.$store.state.isSubscribed && list.length > 5) {
        return list.slice(0, 5)
        } else  {
          return list
        }
      }
      
    },
    async fetchRandomMusic(count) {
      this.loading = true
      await axios.get(`${this.base_url}/home/random/${count}`)
        .then(r => {
          const raw = r.data.data
          this.musics = this.attachLikesFaves(raw)
        })
      this.loading = false
    },
    renamePlaylist(playlist) {
      var id = playlist.id
      console.info(playlist)
      console.log("RENAME")
      var name = playlist.name
      const dialog = this.$buefy.dialog.prompt({
        message: `Rename Playlist`,
        inputAttrs: {
          type: 'text',
          placeholder: 'Playlist name',
          value: name,
          maxlength: 25
        },
        confirmText: 'Send',
        trapFocus: true,
        closeOnConfirm: false,
        canCancel: true,
        onConfirm: async value => {
          if (this.nameExists(value, id)) {
            this.$buefy.toast.open({
              message: `A Playlist named '${value}' already exists.`,
              type: 'is-danger'
            })
          } else {
            await axios.put(`${this.base_url}/playlist/${id}`, {
              name: value
            })
            .then(() => {
              this.$store.dispatch('fetchUserPlaylists')
              this.$buefy.toast.open("Playlist renamed")
              dialog.close()
            })
          }
        }
      })
    },
    deletePlaylist(id) {
      var self = this
      this.$buefy.dialog.confirm({
        title: 'Remove from Playlist',
        message: 'Are you sure you want to <b>REMOVE</b> your playlist?<br>This will remove all the songs associate with this playlist. This action cannot be undone.',
        confirmText: 'Delete Playlist',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          await axios.delete(`${this.base_url}/playlists/${id}`)
            .then((response) => {
              // this.items = this.musics
              console.log("Playlist")

              var removeIndex = self.playlists.map(function(item) { return item.id; }).indexOf(id);
console.log("ID "+id)
// remove object
              self.playlists.splice(removeIndex, 1);

              // console.info(response)
              // console.info(self.playlists)
              // self.playlists = self.playlists.filter(function(item){
              //   return item.id.indexOf(id) >= 0
              // })

              console.info(self.playlists)
              // this.playlistOnView = 1
              // this.$store.dispatch('fetchUserPlaylists')
              this.$buefy.toast.open("Playlist removed!")
            })
        }
      })
    },
    async removeSongFromPlaylist(music_id, name) {
      console.log("removeSongFromPlaylist")
      const playlist = this.playlists.find(item => item.id === this.playlistOnView)
      const songs = playlist.songs.filter(song => song !== music_id)
      this.$buefy.dialog.confirm({
        message: `Remove song titled <span style="font-weight: bold">"${name}"</span> from playlist <span style="text-transform: capitalize">${playlist.name}</span>?`,
        onConfirm: () => {
          this.$store.dispatch('addMusicToPlaylist', {
            playlist_id: this.playlistOnView,
            songs: songs
          })
          .then(() => {
            this.$buefy.toast.open('Song removed from playlist.')
          })
        }
      })
    },
    initPlaylist () {
      if (this.$store.state.isLoggedin) {
        this.userID = this.$store.state.user.id
        this.liked = this.attachLikesFaves(this.$store.state.likes)
        this.favorites = this.attachLikesFaves(this.$store.state.favorites)
      }
    },
    nameExists (name, id) {
      console.log("nameExists")
      const playlists = this.playlists.filter(playlist => playlist.id !== id)
      const expression = new RegExp(name, 'gi')
      const exists = playlists.some(playlist => expression.test(playlist.name))
      return exists
    }
  },
  async created() {
    if (this.$store.state.isLoggedin) {
      this.userID = this.$store.state.user.id
      this.liked = this.attachLikesFaves(this.$store.state.likes)
      this.favorites = this.attachLikesFaves(this.$store.state.favorites)
    }
    this.base_url = `${this.$store.state.base_url}api/v1`

    if(this.isAdmin){
      console.log('Created Fetch Music 100')
      console.info(this.isAdmin)
      await this.fetchRandomMusic(500)
    }
    else{
      console.log('Created Fetch Music 15')
      console.info(this.isAdmin)
      await this.fetchRandomMusic(15)
    }
      
    
    
    this.items = this.musics
  }
}
</script>

<style lang="scss" scoped>
.highlight{
  color:red;
  margin: 0px !important;
}
 .myPlaylistTitle{
   float:left;
   cursor: move;
   margin: 3px;
 }
.running-playlist {
  color: #4af9ff;
  font-weight: bold;
}
.playlist-icon-playing-indicator {
  position: absolute;
  top: 5px;
  left: 76%;
  background-color: #595959;
  font-size: .85rem;
  width: 140px;
  box-sizing: border-box;
  padding: .25em .75em .25em 1.75em;
  border-radius: 0 25px 25px 0;
  z-index: -1;
  cursor: pointer;
}
.liked-active, .playing {
    color: rgb(0, 138, 223) !important;
}

.favorite-active {
    color: rgb(199, 0, 0) !important;
}

.clickable {
  cursor: pointer;
}
</style>
